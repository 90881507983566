/*------------------------------------*\
  Front Page - Login
\*------------------------------------*/
body.front {
  &.not-logged-in {
    background-color: @black;
    /** Header **/
    .header {
      background: @black;
      position: sticky;
      top: 0;
      padding-top: 0;
      padding-bottom: 0;

      .topbar {
        padding: 10px 0;

        .region-topbar {
          justify-content: center;
          flex-wrap: wrap;
        }

        .logo {
          display: none;
        }

        .front-page-logos {
          padding-top: 5px;
          padding-bottom: 5px;
          p {
            display: flex;
            gap: 1rem;
            justify-content: center;
          }

          img {
            max-width: 100%;
            height: 15px;
            width: auto;
            flex: 1;
          }
        }

        .block-menu {
          padding-top: 10px;
          padding-bottom: 0;
          .menu {
            display: flex;
            justify-content: center;
            > li {
              padding: 0 5px;
            }
          }
        }

        .btn {
          border-radius: 8px !important;
          text-transform: uppercase !important;
          padding: 2px 15px;
          font-weight: bold;
          color: @black;
          font-size: 10px;

          &:hover {
            background-color: unset !important;
            color: @white;
            border-color: @white;
          }

          &.contact-btn.btn-outline {
            background-color: @theme-color-1;
            border-color: @theme-color-1;
            color: @black;
            padding: 5px 15px !important;

            &:hover {
              background-color: @white;
              border-color: @white;
            }
          }

          &.btn-white {
            background-color: @white;
            border-color: @white;
          }
        }
      }

      /** Responsive **/
      @media @tablet {
        .topbar {
          padding: 10px 20px;
          .front-page-logos,
          .block-menu {
            padding-top: 0;
            padding-bottom: 0;
          }

          .block-menu {
            .menu {
              > li {
                padding: 5px;
              }
            }
          }

          .front-page-logos {
            img {
              height: 20px;
            }
          }
        }
      }
      @media screen and (min-width: 1440px) {
        .topbar {
          .front-page-logos {
            p {
              gap: 4rem;
            }
            img {
              height: 30px;
            }
          }
        }
      }
      @media screen and (min-width: 960px) {
        .topbar {
          .region-topbar {
            justify-content: space-between;
          }
          .front-page-logos {
            width: 50%;
          }
          .btn {
            font-size: 16px;
          }
        }
      }
    }

    /** Custom Footer **/
    .footer {
      overflow: hidden;
      width: 100%;
      margin-top: auto;

      .region {
        flex-direction: column;
        .block {
          margin-bottom: 20px;
        }
      }

      /** Responsive **/
      @media screen and (max-width: 1200px) {
        padding: 0;
        .region {
          gap: 1rem;
        }
      }
      @media screen and (min-width: 767px) {
        .region {
          flex-direction: row;
        }
      }

      .footer-links {
        margin: 0 auto 0 0;
        .nav {
          flex-direction: column;
          li {
            width: 100%;
            line-height: 1;

            &:not(:first-child) {
              margin-top: 4px;
            }

            a {
              font-size: 12px;
              width: 100%;
              text-align: center;
            }
          }
        }
        /** Responsive **/
        @media all and (max-width: 1200px) {
          margin: 0;
          padding-bottom: 0;
        }

        @media @tablet {
          .nav {
            li {
              border-left: 1px solid #fff;
              a {
                text-align: left;
                padding: 0 0.5rem !important;
              }
            }
          }
        }
        //
      }

      #block-koda-user-management-active-user-count,
      #block-koda-user-management-minutes-saved-count {
        margin-left: 0;
        flex: 0 0 auto;
        gap: 10px;

        .block-title {
          font-size: 16px;
          line-height: 1.1;
          width: 100%;
          font-weight: 600;
        }

        .counter-container {
          align-content: center;
        }

        .counter {
          font-size: 35px;
          padding: 0;
        }

        .counter-text {
          display: none;
        }

        /** Responsive **/
        @media screen and (min-width: 767px) {
          .block-title {
            width: 120px;
          }
        }
      }
    }

    .main-sidebar {
      display: none;
    }

    /** Responsive **/
    @media @tablet {
      .footer {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 1rem;

        .region {
          .block {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

// Home
.front-page {
  background-color: @black;
  color: @white;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 93.5vh;
  min-height: 800px;
  align-items: stretch;

  h1 {
    color: inherit;
  }

  p {
    font-size: 15px;
  }

  > .col {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: @black;
    color: #fff;
    width: 100%;

    img {
      &.cover-image {
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &.login-logo {
        margin-bottom: 1.5rem;
        max-width: 100%;
        /** Responsive **/
        @media screen and (min-width: 1280px) {
          margin-top: auto;
        }
      }
    }

    .cover-message {
      display: flex;
      position: absolute;
      left: 0;
      padding: 6rem 2rem;
      bottom: 0;
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: flex-end;
      background: linear-gradient(
        360deg,
        #000000 0%,
        rgba(0, 0, 0, 0.464644) 34.83%,
        rgba(0, 0, 0, 0) 56.28%
      );

      p {
        font-size: 14px;
      }

      a {
        color: @theme-color-1;
      }

      &__content {
        display: none;
        position: relative;
        width: 100%;
        border-left: 3px solid @theme-color-1;
        padding-left: 2rem;
        font-size: 12px;
      }
      /** Responsive **/
      @media @tablet {
        img.cover-image {
          position: absolute;
        }
      }
      @media @xwide {
        padding: 6rem;
      }
      @media screen and (min-width: 1054px) {
        &__content {
          display: block;
        }
      }

      @media @xwide {
        p {
          font-size: 20px;
        }
      }
    }

    &.left {
      padding-left: 0;
      padding-right: 0;
      //height: 250px;
    }

    &.right {
      padding: 2rem;
      flex: 1;
    }

    .region {
      width: 100%;
    }

    .well {
      margin: 0;
      padding: 0;
      border-radius: 0;
      border: none;
      background: none;
    }

    h1 {
      font-size: 25px;
      font-weight: 600;
    }

    .btn.bigbtn {
      padding: 5px 15px;
      margin: 2rem 0;
      font-size: 16px;
      min-width: 200px;
    }

    #block-user-login {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;

      h2 {
        display: none;
      }

      label {
        font-weight: normal;
      }

      .form-control {
        height: 45px;
        border-radius: 6px !important;
      }

      .form-actions {
        display: flex;
      }

      .g-recaptcha {
        margin: -5px -30px 0 -30px;
        padding: 0;
        display: flex;
      }

      .captcha {
        position: relative;
        display: flex;
        margin: 0 0 10px 0;
        height: 70px;
      }

      .btn {
        .icon {
          display: none;
        }
      }
    }

    .login-content {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin: 0;

      .region-content {
        text-align: center;
      }

      #user-login-form {
        ul {
          display: table;
          justify-content: center;
          padding-left: 0;
          margin: 10px auto;
        }
      }

      .form-item {
        text-align: left;
      }

      .login-footer-logo {
        max-width: 200px;
      }

      .form-actions {
        justify-content: center;
        margin-bottom: 10px;
        .btn {
          margin: 0 !important;
          min-width: 150px;
        }
      }

      .captcha {
        justify-content: center;
      }

      /** Responsive **/
      @media @tablet {
        height: 100%;
      }

      @media screen and (min-width: 767px) {
        .login-footer-logo {
          margin-top: 10%;
        }
      }
    }
  }

  // Responsive
  @media screen and (min-width: 1250px) {
    > .col {
      &.right {
        order: 0;
        flex: 0 0 60%;
        padding: 1rem 6rem 120px 6rem;

        .login-content {
          width: 450px;
        }
      }
    }
  }

  @media @tablet {
    flex-direction: row;

    > .col {
      width: auto;
      justify-content: center;
      align-items: center;

      &.left {
        flex: 1;
        order: 1;
        height: 100%;
      }

      &.right {
        flex: 0 0 800px;
      }

      .login-content {
        justify-content: center;
      }
    }
  }

  @media @xwide {
    > .col {
      &.right {
        flex: 0 0 930px;
      }
    }
  }
}
