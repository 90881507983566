// Footer
.footer {
  padding: 3em 0 2em 0;
  margin: 0;
  color: @white;
  background: @black;
  background-size: cover;
  position: relative;
  z-index: 1;
  border: none;
  overflow: hidden;

  .container {
    background: none;
    position: relative;
    z-index: 1000;
  }

  .region-footer {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  @media @xwide {
    padding-right: 200px;
    .region-footer {
      flex-wrap: nowrap;
      justify-content: flex-start;
    }

    .footer-logo {
      width: auto;
      margin-bottom: 0;
    }
  }

  // Footer Logo
  .footer-logo {
    display: flex;
    justify-content: center;
    margin-left: 0;
    margin-bottom: 1rem;
    width: 100%;
    flex: 0 0 250px;

    img {
      max-width: 220px;
      width: 100%;
      height: auto;
      .myanimated;
    }
  }

  // Footer Links
  .footer-links {
    position: relative;
    margin: 1rem;
    width: 100%;

    a {
      display: inline-block;
      margin: 0 0.25rem;
    }

    .nav {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: column;

      li {
        display: flex;
        flex: 0 0 auto;
        a {
          font-size: 16px;
          padding: 0 1rem !important;
        }
      }
    }

    @media @tablet {
      flex: 0 0 auto;
      width: auto;
      .nav {
        flex-direction: row;
        li {
          &:not(:first-child) {
            border-left: 1px solid #fff;
          }
        }
      }
    }

    @media @xwide {
      margin-left: 120px;
    }
  }

  // Common Footer Block
  .footer-block {
    padding-top: 0;
    font-size: @font-size-small;

    @media @tablet {
      padding-top: 0;
    }

    h2,
    h3 {
      font-size: @font-size-large;
      margin: 0 0 0.5em 0;
      text-align: left;
      color: @white;
    }

    a {
      color: @brand-primary;

      &:hover {
        color: @theme-color-1;
        text-decoration: underline;
      }
    }

    .fa {
      font-size: @font-size-large;
      font-weight: normal;
      color: @gray-light;

      &:hover {
        color: @theme-color-1;
        text-decoration: none;
      }
    }

    .nav {
      margin: 0;
      padding: 0;
      li {
        margin: 0;
        padding: 0;
        display: block;

        a {
          margin: 0;
          padding: 0 0 0.5em 0;
          background: none;
          color: @theme-color-1;

          &:hover {
            color: @theme-color-2;
            text-decoration: underline;
          }
        }
      }
    }

    &.download-links {
      .nav {
        li {
          a {
            &:before {
              content: '\f019';
              font-family: @font-family-awesome;
              position: relative;
              display: inline-block;
              color: @white;
              padding-right: 0.5em;
            }
          }
        }
      }
    }
  }
}
