// UC Product

.uc-product-node {
  .view-mode-product_without_purchase {
    .field-name-uc-product-image {
      .field-items {
        .clearfix;

        .field-item {
          display: inline-block;
          vertical-align: top;
          margin-right: 1em;

          img {
            display: inline-block;
            height: 160px;
            width: auto;
          }
        }
      }
    }
  }

  .view-mode-full {
    .group-img {
      .field-items {
        .clearfix;

        .field-item {
          width: 33.333333%;
          float: left;
          box-sizing: border-box;
          padding: 0 @grid-gutter-width / 2;
          margin: 0 0 1em 0;

          img {
            width: 100%;
            height: 120px;
            .object-fit(contain);
          }

          @media @wide {
            width: 25%;
          }

          &:nth-child(1) {
            width: 100%;
            padding: 0;
            text-align: center;

            img {
              display: inline-block;
              width: auto;
              height: auto;
            }
          }
        }
      }
    }

    // .display-price {
    //   margin: 1.5 * @grid-gutter-width @grid-gutter-width 0 0;
    // }
  }
}

// Purchase Product
.page-purchase-products {
  #cboxWrapper {
    #cboxClose {
      display: none;
    }
  }
}
