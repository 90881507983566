// Content
.content {
  background-color: #fafafa;
  padding: 0;

  &.main-content {
    > .container-fluid {
      min-height: 80vh;
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      padding-left: 0;
      padding-right: 0;

      .content-col {
        flex: 1;
        width: 100%;
        padding: 1.5rem 1rem;

        .page-header {
          margin: 1rem 0;
        }

        &.with-left-sidebar {
          padding-left: 80px;
        }
      }

      @media @wide {
        .content-col {
          padding: 1.5rem 3rem;
          &.with-left-sidebar {
            padding-left: 3rem;
          }
        }
      }
    }
  }
}

#preloader {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background: rgba(255, 255, 255, 0.9);
}

/** Page that show preloader - js hides after load **/
body {
  &.page-purchase-products,
  &.page-cart {
    #preloader {
      display: flex;
      z-index: 100;
    }
  }
}

// Content Page Header
.content-page-header {
  display: flex;
  flex-direction: column;
  .page-header {
    flex: 1;
    margin-bottom: 15px;
  }
  .region-title-sidebar {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  @media @tablet {
    flex-wrap: wrap;
    flex-direction: row;
    .page-header,
    .region-title-sidebar {
      margin-bottom: 0;
    }
  }
  @media @normal {
    width: 60%;
  }
  @media @wide {
    width: 70%;
  }
}

// Two Columns
.content-two-cols {
  .inner-content {
    margin-bottom: 2rem;
  }
  @media @normal {
    display: flex;
    gap: 2rem;
    .inner-content {
      width: 60%;
      margin-bottom: 0;
    }
    .sidebar__right {
      flex: 1;
    }
  }
  @media @wide {
    .inner-content {
      width: 70%;
      margin-bottom: 0;
    }
  }
  @media @xwide {
    gap: 4rem;
  }
}
