form {
  &[action*='contact-us'] {
    @media @tablet {
      margin: 0 auto;
      > div {
        display: flex;
        width: 100%;
        gap: 20px;
        flex-direction: row;
        flex-wrap: wrap;

        > .form-item {
          width: calc(50% - 10px);
          &.webform-component--header,
          &.webform-component--message {
            width: 100%;
          }
        }
        > .form-actions {
          width: 100%;
        }
      }
    }
    @media @normal {
      width: 50%;
    }
  }
}
