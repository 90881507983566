// Tables

table {
  &.no-border {
    > thead > tr > th,
    > tbody > tr > th,
    > tfoot > tr > th,
    > thead > tr > td,
    > tbody > tr > td,
    > tfoot > tr > td {
      border-top: none;
    }
  }
  thead,
  tbody {
    a {
      color: #000;
    }
    th {
      font-weight: normal;
      .icon {
        margin-left: 0.5rem;
        &::before {
          font-weight: normal !important;
        }
      }
    }
  }
}

table#sort-table.account-based-order-approved {
  > tbody {
    tr {
      td:last-child {
        width: 200px;
      }
    }
  }
}

table#sort-table.user-based-order-approved {
  > tbody {
    tr {
      td:last-child {
        width: 200px;
      }
    }
  }
}

.scrollable-table {
  table.table {
    width: 100%; /* 140px * 5 column + 16px scrollbar width */
    border-spacing: 0;
  }

  table.table tbody,
  table.table thead tr {
    display: block;
  }

  table.table tbody {
    height: 100px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  thead tr th {
    height: 30px;
    line-height: 30px;
  }
}
.rww-checkout-cont {
  padding: 0 1rem;
  height: 100%;

  .sub-check-cont {
    &:not(:last-child) {
      border-bottom:1px dotted #ccc;
    }
    
    label {
      font-size: 20px;
      font-weight: 600;
    }
    &:nth-child(2) {
      margin-top: 15px;
    }
    .sub-desc {
      &:nth-child(2) {
        margin-bottom: 1rem;
      }
    }
  }
  
  .rww-store-order-table {

    tr {
       &:nth-child(2) {
          background: #fafafa;
        }
      td {
        border-top: none;
        vertical-align: middle;
        padding: 5px 8px;


      }
      .image {
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            max-height: 60px;
          }
        }
      }
      .description {
        font-weight: 600;
        width: 75%;
        padding-right: 50px;
        font-size: 15px;
      }
      .total-value {
        text-align: right;
        font-weight: 600;
      }
      .total-label {
        text-align: right;
        font-weight: 600;
      }
    }
  }
}
.page-cart {
  #cboxClose {
    top: 0;
  }
}