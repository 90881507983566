/*------------------------------------*\
  Custom Login for specific account
\*------------------------------------*/
.node-type-custom-login-page {
  .content-col {
    background-color: #000;
    color: #fff;
    h1,
    h2,
    h3 {
      color: @brand-primary;
    }
  }

  .block-auth0 {
    margin-top: 2rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
    background: url('../images/home-img.jpg') center center no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
  }

  #auth0-login-form {
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
