/*------------------------------------*\
  Staff
\*------------------------------------*/
.view-staff-listing {
  position: relative;
  .view-content {
    .clearfix;
    .staff-item {
      .views-field {
        display: flex;
        line-height: 1.5;
        color: #000;

        > .field-content {
          width: 100%;
        }

        &.views-field-field-user-subgroup-ref {
          flex-wrap: wrap;
          .field-content {
            position: relative;
            padding-left: 0;
            width: 100%;
            font-size: 14px;
            flex: 1;
            ul {
              list-style: none;
              padding-left: 0;
              margin-left: 0;
              margin-bottom: 2px;
              > li {
                &:not(:last-child) {
                  &::after {
                    content: ' ,';
                  }
                }
              }

              &.set-limit {
                > li {
                  &:first-child {
                    &::after {
                      content: ' ...';
                    }
                  }
                  &:not(:nth-child(1)) {
                    display: none;
                  }
                }
              }
            }
          }

          /** More Button **/
          .list-show-more {
            display: inline-flex;
            width: auto;
            cursor: pointer;
            margin-left: auto;
            text-align: right;
            font-size: 12px;
            border: 1px solid #d8d8d8;
            background-color: #fff;
            color: #000;
            padding: 1px 8px;
            border-radius: 8px;
            text-decoration: none;
            &:hover {
              border-color: @theme-color-2;
              background-color: @theme-color-2;
              color: #fff;
            }
            &::before {
              margin-right: 4px;
              content: '\f063';
              font-family: @font-family-awesome;
            }
            &.enabled {
              &::before {
                content: '\f062';
              }
            }
          }
        }

        .views-label {
          flex: 0 0 50%;
          padding-right: 20px;
          color: #696969;
        }

        .staff-header {
          position: relative;
          width: 100%;
          display: flex;
          margin-bottom: 20px;
          h4 {
            display: flex;
            flex: 1;
            align-items: center;
            position: relative;
            margin: 0 !important;
            font-size: 18px;
            font-weight: 600;
            a {
              color: inherit;
            }
          }
          .edit-options {
            > a {
              margin-left: 5px;
              text-decoration: none;
              &::before {
                font-weight: bold;
                font-family: @font-family-awesome;
                text-decoration: none;
              }
              &#staff-edit {
                &::before {
                  content: '\f303';
                }
              }
              &#staff-delete {
                &::before {
                  content: '\f2ed';
                }
              }
            }
          }
        }

        .bottom-links {
          margin: 10px 0 0 0;
          padding: 0;
          li {
            margin: 0;
            padding: 0;
            list-style: none;
            &::before {
              content: '\f105';
              font-family: @font-family-awesome;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  .view-footer {
    display: flex;
    flex-wrap: wrap;
  }

  // Horizontal
  &.koda-view-smaller-listing {
    .view-content {
      padding: 0.5rem !important;

      .staff-item {
        font-size: 15px;
        padding-bottom: 1rem;
        &:not(:first-child) {
          border-top: 1px solid #d8d8d8;
          padding-top: 1rem;
        }
        .staff-header {
          margin-bottom: 0;
          h4 {
            font-size: 16px;
          }
        }

        @media @normal {
          > .views-field {
            .views-label {
              flex: 0 0 200px;
            }
          }
        }
        @media @xwide {
          font-size: 16px;
          display: flex;
          width: 100%;
          padding: 1rem;
          align-items: flex-start;
          justify-content: flex-start;
          h4 {
            font-size: 18px;
          }
          > .views-field {
            justify-content: space-between;
            flex: 1;
            padding-right: 1.5rem;

            &:nth-child(2),
            &:nth-child(3) {
              flex: 0 0 300px;
              padding-left: 0;
              .views-label {
                flex: 0 0 150px;
              }
            }
            &:nth-child(4) {
              flex: 0 0 300px;
              padding-left: 0;
            }
            &:last-child {
              padding-left: 1.5rem;
              padding-right: 0;
              flex: 0 0 150px;
              margin-left: auto;
            }
          }

          &:hover {
            background-color: #fffeda;
          }
        }
      }
    }
  }
}
