// Terms

.page-taxonomy-term {
  .region-content {
    display: flex;
    flex-wrap: wrap;

    > #block-system-main {
      flex: 0 0 100%;
    }
    > .block-simple-taxonomy-menu {
      flex: 0 0 250px;
    }
    > .block-simple-taxonomy-menu + .block-views {
      flex: 0 0 82.5%;
    }
  }

  // If product
  &.has-offset-sidebar {
    .koda-view-bigger-listing {
      .view-three-columns;
    }
    .block-simple-taxonomy-menu {
      overflow: hidden;
      flex: 0 !important;
      opacity: 0;
    }
  }

  &:not(.has-offset-sidebar) {
    .koda-view-bigger-listing {
      .view-four-columns;
    }
  }

  .region-content {
    > .block-simple-taxonomy-menu + .block-views {
      flex: 1;
    }
  }
}

.taxonomy-term {
  &.view-mode-term_teaser {
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);

    .field-name-field-category-image,
    .field-type-image {
      img {
        margin: 0 auto;
        height: 230px;
        width: 100%;
        object-fit: cover;
      }
    }

    &:hover {
      .field-name-title {
        height: 100%;
        .field-items,
        .field-item {
          height: 100%;
        }
        a {
          color: #fade2f;
        }
      }
    }

    .field-name-title {
      text-align: center;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      .myanimated;
      .field-items,
      .field-item {
        .myanimated;
      }

      h4 {
        display: flex;
        justify-content: center;
        margin: 0;
        font-size: 16px;
        width: 100%;
        height: 100%;
      }
      a {
        .myanimated;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        outline: none;
        width: 100%;
        height: 100%;
        padding: 10px;
        color: #fff;
        text-transform: uppercase;
        background-color: rgba(0, 0, 0, 0.8);
      }
    }

    a {
      &:active,
      &:focus {
        text-decoration: none;
        outline: none;
      }
    }
  }

  .field-name-back-to-term-link {
    a {
      margin-top: 1rem;
      .btn;
    }
  }
}

// Apply view purchase products style
.view-subcategory-terms-and-products {
  .view-purchase-products;
  .view-header {
    background-color: #fff;
    border: 1px solid #d8d8d8;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 0;
  }
}
