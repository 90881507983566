/*------------------------------------*\
  Make Region Sticky
\*------------------------------------*/
.sticky-region {
  position: sticky;
  top: 60px;
  z-index: 10;
  @media @tablet {
    top: 100px;
  }
}
