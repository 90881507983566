// Sidebar
.mobile_header {
  display: block;
  padding: 0.5em 15px;
  background: @theme-color-3;
  color: @white;
  vertical-align: middle;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  .box-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));

  @media @normal {
    display: none;
  }

  .menu-btn {
    display: inline-block;
    font-size: 18pt;
    font-weight: normal;
    margin-right: 0.5em;
    padding: 0.25em;
    border: none;
    .border-radius(3px);
    width: 40px;
    height: 40px;
    text-align: center;
    cursor: pointer;
    color: @theme-color-2;
  }

  .header-title {
    display: inline-block;
    font-size: @font-size-large;
    line-height: 1;
    font-weight: 300;
    padding: 0;

    img {
      width: auto;
      height: 40px;
    }
  }
}
