html,
body {
  min-height: 100%;
  position: relative;
  margin: 0 !important;
  font-weight: 400;
  font-size: @font-size-small;
  @media @normal {
    font-size: @font-size-base;
  }
}

a,
.btn,
button,
.myanimated {
  .transition(all 300ms ease);
}

h1,
.page-header {
  margin: 0 0 0.5rem 0;
  padding: 0;
  color: #000;
  font-size: 30px;
  font-weight: 600;
  border: none;
}
h2,
h3,
h4,
h5 {
  margin: 1em 0 0.5em 0;
  padding: 0;
  color: @black;
  font-weight: 300;
}
h2.block-title {
  margin: 0 0 1em 0;
  font-size: @font-size-h3;
  text-align: center;
  @media @tablet {
    font-size: @font-size-h2;
  }
  @media @wide {
    font-size: @font-size-h1;
  }
}
#admin-menu {
  font-size: 12px;
  display: none;
  @media @wide {
    display: block;
  }
}
html body.admin-menu {
  margin-top: 0 !important;
  padding-top: 0 !important;
  @media @wide {
    padding-top: 29px !important;
  }
}
body.noscroll {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  @media @normal {
    position: static;
  }
}

.btn {
  border-radius: 72px;
  margin-right: 0.5rem;
  padding: 0.3rem 1.5rem;
  margin-bottom: 1rem;
  background-color: @theme-color-1;
  border-color: @theme-color-1;
  color: #000;

  &.close-rww-survey-popup {
    background-color: #0f0e0e;
    border: 1px solid #454545;
    color: #fff;
    &:hover {
      color: #000;
    }
  }

  &:hover {
    border-color: @theme-color-2;
    background-color: @theme-color-2;
    color: #000;
  }

  &.btn-outline {
    border-color: #fff;
    background-color: unset;
    color: #fff;
    &:hover {
      border-color: @theme-color-2;
      color: @theme-color-2;
      background-color: unset;
    }
  }
}

&.yellowbtn {
  background: @theme-color-2;
  border: 1px solid @theme-color-2;
  color: @black;
  &:hover {
    background: lighten(@theme-color-2, 10%);
    color: @black;
  }
}

&.graybtn {
  background: @gray-lighter;
  border: 1px solid @gray-light;
  color: @gray;
  &:hover {
    background: @gray-light;
    color: @gray-mid-dark;
  }
}
&.blackbtn {
  background: @theme-color-3;
  border: 1px solid @theme-color-3;
  color: @white;
  &:hover {
    background: darken(@theme-color-3, 10%);
    color: @white;
  }
}
&.bigbtn {
  padding: 0.75em 2em;
  font-size: @font-size-large;
}
.blue {
  color: @theme-color-1;
}
.light-blue {
  color: @theme-color-2;
}
.underlined {
  position: relative;
  padding-bottom: 1rem;
  &:after {
    height: 1px;
    width: 40px;
    position: absolute;
    left: 50%;
    bottom: 0;
    margin-left: -20px;
    content: '';
    display: block;
    background: @theme-color-2;
  }
}
