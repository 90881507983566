/*------------------------------------*\
  Popup box
\*------------------------------------*/

#popup-message-window {
  background: #222222;
  border: 3px solid #ffffff;
  color: #fff;
  padding: 0 30px 20px 30px;
  -webkit-box-shadow: 0 0 40px 5px #000000;
  box-shadow: 0 0 40px 5px #000000;

  h1.popup-message-title {
    font-weight: 600;
    color: #ffffff;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 3px solid #fade2f;
  }

  @media screen and (max-width: 600px) {
    width: 380px !important;
    left: 0 !important;
    top: 75px !important;
    height: 500px !important;
  }
}

#popup-message-close {
  right: 12px;
  top: 12px;
}

/*------------------------------------*\
  Popup Details
\*------------------------------------*/

.budget-details-popup {
  position: relative;

  #budget-details-close-button {
    display: none;
  }

  #budget-details-close-button-icon {
    border: none;
    padding: 0;
    background: none;
    margin: 0;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 0;

    &:before {
      font-family: @font-family-awesome;
      font-size: @font-size-large;
      content: '\f057';
    }
  }

  .budget-details-popup-title {
    font-weight: bold;
    margin: 0 0 10px 0;
    padding-bottom: 5px;
    border-bottom: 1px solid #000;
    text-transform: uppercase;
  }

  .budget-details-popup-body {
    .budget-term-name {
      font-weight: bold;
    }
  }
}

#popup-message-window {
  padding: 0px 20px;
  font-size: 12pt;
}

#popup-message-window h1.popup-message-title {
  margin-top: 0;
  padding-top: 0;
}
