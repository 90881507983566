// Sidebar Toggle
.sidebar-toggle {
  margin-top: 0.8rem;
  margin-right: 0.8rem;
  margin-left: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background-color: #e5e5e5;
  color: @gray-base;
  text-decoration: none;
  &:hover,
  &:active {
    text-decoration: none;
  }
  &::before {
    content: '\f0c9';
    font-size: 18px;
    line-height: 1;
    font-family: @font-family-awesome;
    text-decoration: none;
  }

  // Responsive
  @media @wide {
  }
}

.hide-icon-text {
  color: transparent;
  justify-content: center;
  padding-left: 0;
  font-size: 0;
  &::before {
    position: relative;
    font-size: 16px;
    margin-right: 0;
    color: #8c8c8c;
  }
}

.show-icon-text {
  font-size: 16px;
  padding-left: 35px;
  justify-content: flex-start;
  width: auto;
  color: @gray-base;
  &::before {
    position: absolute;
    font-size: 16px;
    color: #8c8c8c;
  }
}
