/*------------------------------------*\
  Node Product
\*------------------------------------*/
.node-type-product {
  .add-to-cart {
    .product-extra-tam_img,
    .product-extra-tam_desc,
    .product-extra-tam_stock_stock,
    .product-extra-tam_stock_price {
      display: none;
    }
  }
}

.page-purchase-products,
.uc-product-node.mode_once-processed.click_once-processed,
.page-purchase-ppe-products {
  .page-header {
    @media @normal {
      margin-top: 1em;
    }
  }
}

/** Purchase Products **/
.view-purchase-products {
  // Bigger ( tiles )
  &.koda-view-bigger-listing {
    .view-content {
      > .product-item {
        .product-extra-tam_logo_name,
        .product-extra-tam_stock_price {
          display: none;
        }

        .product-extra-tam_colour,
        .product-extra-tam_size {
          .control-label {
            display: none !important;
          }
          .form-control {
            width: 100%;
          }
        }

        > .views-field-addtocartlink,
        > .views-field-instorepurchase {
          width: 100%;
          form {
            > div {
              display: flex;
              flex-wrap: wrap;
              width: 100%;
            }
            .product-extra {
              width: 100%;
            }

            .product-extra-tam_desc {
              line-height: 1.2;
              font-weight: 600;
              a {
                font-size: 15px;
              }
            }

            .product-extra-tam_img {
              margin-bottom: 20px;
              img {
                height: 150px;
              }
            }

            .form-item {
              display: flex;
              flex-direction: column;
              &.form-item-qty {
                display: flex;
                order: 0;
                flex: 0 0 80px;
                flex-direction: row;
                label {
                  display: flex;
                  align-items: center;
                  margin-bottom: 0;
                  font-weight: normal;
                }
                span {
                  display: none;
                }
                .form-control {
                  padding: 6px;
                  text-align: center;
                }
              }
            }

            .display-price {
              margin-left: auto;
              padding: 0;
              color: @theme-color-2;
              font-size: 25px;
              font-weight: 600;
              width: 40%;
              .uc-price {
                display: flex;
                flex-direction: column;
                line-height: 1;
                align-items: flex-end;
                &::after {
                  content: 'ex GST';
                  font-size: 12px;
                  margin-left: 8px;
                }
              }
            }

            .magical-modal-link {
              cursor: pointer;
              order: 1;
              padding-left: 8px;
              img {
                object-fit: contain;
              }
            }

            .product-info {
              order: 2;
            }

            .toggle-cart-form {
              order: 3;
              width: 40%;
              height: 38px;
              padding-left: 10px;
              padding-right: 10px;
              margin-right: 10px;

              &.btn {
                background-color: #000;
                border-color: #000;
                color: #fff;
              }
            }

            .form-actions {
              position: relative;
              order: 4;
              width: 55%;
              padding: 0;

              .uc-ajax-cart-alt-status-messages {
                position: absolute;
                bottom: 450%;
                width: 175%;
                right: 0;
                padding: 0;
                margin: 0;
                line-height: 1;
                font-size: 14px;
                .alert {
                  margin-bottom: 0;
                  text-align: left;
                }
              }

              .btn {
                width: 100%;
                margin: 0;
                padding-left: 10px;
                padding-right: 10px;
                height: 38px;
                .icon {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .view-content {
    .product-item {
      flex-direction: row;
      align-items: center;

      .views-field-instorepurchase,
      .views-field-addtocartlink {
        flex: 1;
      }
      .pass-data {
        &.btn {
          margin: 0;
        }
      }
      .product-extra {
        display: block;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;

        > * {
          flex: 1 1 100%;
        }

        .product-extra-tam_img {
          img {
            width: 100%;
            height: 150px;
            .object-fit(contain);
          }
        }
      }

      .product-extra-tam_title {
        font-size: @font-size-base;
        margin: 0.5rem 0 1rem 0;
        font-weight: 400;
      }

      .product-extra-tam_desc {
        font-size: @font-size-large;
        font-weight: 400;
        height: auto !important;
        text-align: center;
      }

      form > div {
        .form-actions {
          position: relative;

          .logo-over-addtocart-button {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 10;
            position: absolute;
            display: block;
          }
        }
      }
    }
  }

  .view-header {
    background: @gray-lightest;
    padding: 1em @grid-gutter-width / 2;
    display: none;

    @media @tablet {
      display: block;
    }

    .view-switch {
      text-align: right;
    }
  }

  // Tiles
  &.koda-view-bigger-listing {
    .view-content {
      .product-item {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        .product-extra {
          &.product-extra-tam_stock_info_name,
          &.product-extra-tam_stock_info_uuid,
          &.product-extra-tam_stock_info,
          &.product-extra-tam_stock_info_notification,
          &.product-extra-tam_stock_info,
          &.product-extra-selected_sku,
          &.product-extra-tam_stock_price,
          &.product-extra-tam_pack_size,
          &.product-extra-tam_logo_name {
            display: none !important;
          }
        }

        .product-extra-tam_colour,
        .product-extra-tam_size {
          .control-label {
            display: none !important;
          }
        }

        .views-field {
          &.views-field-field-stock-info {
            .productInStock;
          }
          &.views-field-uc-product-image {
            margin-bottom: 1rem;
            img {
              height: 120px;
              width: 100%;
              object-fit: contain;
            }
          }
          &.views-field-field-stockgroup-web-description {
            .field-content {
              font-weight: 600;
            }
          }
          &.views-field-nothing {
            padding-top: 1rem;
            margin-top: auto;
          }
        }
      }
    }
  }

  /** List view - smallet listing **/
  &.koda-view-smaller-listing {
    .view-content {
      .views-row {
        position: relative;
      }

      .product-item {
        display: flex;
        padding-left: 0;

        .views-field-field-stock-info {
          flex: 1;
        }
        .views-field-instorepurchase,
        .views-field-addtocartlink {
          width: 600px;
        }

        .product-extra {
          &.product-extra-tam_colour .control-label,
          &.product-extra-tam_size .control-label,
          &.product-extra-tam_stock_info_name,
          &.product-extra-tam_stock_info_uuid,
          &.product-extra-tam_stock_info,
          &.product-extra-tam_stock_info_notification,
          &.product-extra-selected_sku,
          &.product-extra-tam_stock_price,
          &.product-extra-tam_pack_size,
          &.product-extra-tam_logo_name,
          &.product-extra-tam_stock_info_weight,
          &.product-extra-tam_logo_0,
          &.product-extra-logo_name_0,
          &.product-extra-linked_logo_name,
          &.product-extra-linked_logo_names {
            display: none !important;
          }
        }

        .views-field {
          padding-right: 1rem;
          &.views-field-field-stock-info {
            font-size: 12px;
            top: auto;
            left: 0;
            top: 20px;
            padding: 0 5px;
            position: absolute;
            color: #fff;
            background-color: #7dc323;
            z-index: 2;
            @media @normal {
              top: auto;
              bottom: 0;
            }
          }
        }

        form {
          &[id*='uc-product-add-to-cart-form'] {
            > div {
              width: 100%;
              flex-wrap: nowrap;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-direction: column;

              @media @normal {
                flex-direction: row;
              }

              /** Product Extra **/
              > .product-extra {
                order: 0;
                display: flex;
                flex-direction: column;
                flex: 1;
                width: 100%;
                flex-wrap: nowrap;
                justify-content: flex-start;

                @media @normal {
                  flex-direction: row;
                }

                @media @xwide {
                  // flex: 0 0 70%;
                }

                > .product-extra {
                  display: none;

                  &-tam_title {
                    display: flex;
                    flex: 0 0 100%;
                    width: 100%;
                    justify-content: center;
                    flex-direction: row;
                    margin: auto auto auto 0;
                    @media @normal {
                      flex: 0 0 auto;
                      width: auto;
                    }
                  }

                  &-tam_img {
                    display: flex;
                    flex: 0 0 80px !important;
                    padding-right: 4px;
                    > img {
                      flex: 0 0 auto !important;
                      width: 80px;
                      height: 80px;
                      object-fit: contain;
                    }
                  }
                  &-tam_desc {
                    display: flex;
                    flex: 1;
                    align-items: flex-start;
                    margin-bottom: 0;
                    padding-right: 20px;
                    min-height: auto;
                    height: auto;
                    text-align: left;
                    cursor: pointer;

                    a {
                      line-height: 1.2;
                      font-size: 12px;
                      font-weight: 600;
                    }
                    @media @wide {
                      a {
                        font-size: 15px;
                      }
                    }
                    @media @xwide {
                      flex: 0 0 400px;
                    }
                  }

                  &-tam_colour,
                  &-tam_size {
                    display: flex;
                    flex: 0 0 auto;
                    padding-left: 10px;
                    margin-bottom: 10px;
                    > .form-item {
                      display: flex;
                      width: 100%;
                    }
                    select {
                      position: relative;
                      width: 100%;
                      margin-bottom: 0;
                      padding: 0 2px;
                      font-size: 14px;
                    }

                    @media @normal {
                      flex: 0 0 80px;
                      margin-bottom: 0;
                    }
                  }
                }
              }

              .out-of-stock {
                @media @xwide {
                  min-width: 105px;
                }
              }

              /** Qty **/
              .form-item-qty {
                order: 1;
                width: auto;
                display: flex;
                flex: 0;
                margin-bottom: 0;
                label {
                  font-weight: normal;
                  width: auto;
                  margin: 0;
                  padding: 0 4px;
                  font-size: 14px;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                }
                span {
                  display: none;
                }
                .form-control {
                  width: 100px;
                }
              }

              /** Modal Link **/
              .magical-modal-link {
                order: 2;
                margin-top: 10px;
                @media @normal {
                  margin-left: 10px;
                  margin-top: 0;
                }
              }

              /** Price **/
              .display-price {
                order: 3;
                margin-left: auto;
                margin-right: auto;
                color: @theme-color-2;
                font-weight: 600;
                padding: 10px;
                min-width: 10%;

                .uc-price {
                  display: flex;
                  flex-direction: column;
                  line-height: 1;
                  font-size: 25px !important;
                  align-items: flex-end;
                  &::after {
                    content: 'ex GST';
                    font-size: 12px !important;
                    margin-left: 8px;
                  }
                }

                &.no-stock {
                  color: #fff;
                  .uc-price {
                    color: inherit;
                  }
                }

                @media @normal {
                  padding: 0 10px;
                  margin-right: 10px;
                }
              }

              /** Details **/
              .toggle-cart-form {
                order: 4;
                @media @normal {
                  &.no-stock {
                    /** If Product has no stock **/
                    margin-right: 120px;
                  }
                }
              }

              /** Add to Cart **/
              .form-actions {
                order: 5;
                position: relative;

                .uc-ajax-cart-alt-status-messages {
                  position: absolute;
                  bottom: 0;
                  right: 100%;
                  width: 240px;
                  padding: 0;
                  margin: 0;
                  line-height: 1;
                  font-size: 14px;
                  .alert {
                    margin-bottom: 0;
                    text-align: left;
                  }
                }
                .node-add-to-cart {
                  white-space: normal;
                  margin: 0 0 0 10px;
                  background-color: #000;
                  border-color: #000;
                  color: #fff;
                  .icon,
                  .ajax-progress {
                    display: none !important;
                  }
                }

                @media @normal {
                  flex: 0 0 120px;
                  .node-add-to-cart {
                    padding-left: 10px;
                    padding-right: 10px;
                    width: 100px;
                  }
                }
              }

              .form-item {
                .form-control {
                  width: 100%;
                }
                select,
                input {
                  display: block;
                  float: none;
                  width: 100%;
                  min-width: 40px;
                  padding: 6px;
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }
    }
  }
}

/** If Sidebar is enabled - Shrink these contents**/
body.has-offset-sidebar {
  .view-purchase-products {
    &.koda-view-smaller-listing {
      .view-content {
        .product-item {
          form {
            > div {
              /** Wide Only **/
              @media @xwide {
                > .product-extra {
                  //flex: 0 0 60%;
                  > .product-extra-tam_desc {
                    flex: 1 !important;
                    * {
                      font-size: 12px !important;
                    }
                  }
                  > .product-extra-tam_title {
                    font-size: 12px !important;
                  }
                }

                > .form-item-qty {
                  label {
                    font-size: 12px !important;
                  }
                }

                > .display-price {
                  > .uc-price {
                    font-size: 18px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
