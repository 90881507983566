// User

.page-user {
  .profile {
    .clearfix;

    > .field {
      float: left;
      width: 100%;
      padding: 0;
      margin: 0 0 0.5rem 0;
      box-sizing: border-box;

      @media @tablet {
        width: 50%;
        padding: 0 @grid-gutter-width / 2;
      }

      @media @xwide {
        width: 33.333333%;
      }

      &.field-name-field-user-account-ref {
        width: 100% !important;
        padding: 0;

        .field-label {
          display: block;
          width: 100%;
          margin: 0 0 0.5em 0;
          border-bottom: 1px solid @gray-light;
          padding: 0 0 0.5rem @grid-gutter-width / 2;
        }

        .field-items {
          .clearfix;
          width: 100%;

          .field-item {
            margin: 0 0 0.5em 0;
            float: left;
            width: 100%;
            padding: 0;
            box-sizing: border-box;

            @media @tablet {
              width: 50%;
              padding: 0 @grid-gutter-width / 2;
            }

            @media @xwide {
              width: 33.333333%;
            }
          }
        }
      }

      &.field-name-field-ttt {
        display: none;
      }

      &.field-name-field-private-files {
        width: 100% !important;

        .field-label {
          display: block;
          width: 100%;
          margin: 0 0 0.5em 0;
          border-bottom: 1px solid @gray-light;
          padding: 0 0 0.5rem 0;
        }

        .field-items {
          .clearfix;
          width: 100%;
        }
      }
    }
  }

  .region-content {
    #block-system-main {
      background: #ffffff;
      border: 1px solid #d8d8d8;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
      border-radius: 0px 0px 20px 0px;
      padding: 2rem;
    }
  }
}

// Logged In
.logged-in {
  .auth_off {
    display: none !important;
  }
}

// Non Logged In
.not-logged-in {
  .anon_off {
    display: none !important;
  }
  @media @normal {
    #user-login,
    #user-pass {
      width: 400px;
      margin: 0 auto;
    }
  }
}

.user-info-from-cookie {
  .form-actions {
    .btn {
      margin-right: 0.25rem;
    }
  }
}
