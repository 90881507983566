.alert {
  margin-bottom: 1rem;

  &-message {
    background-color: #f7dbcb;
    color: #000;
    margin-bottom: 1rem;
    padding: 1rem;
    font-size: 15px;
    border-radius: 6px;
    a {
      color: @link-color;
    }

    .block-title {
      font-size: 16px;
      font-weight: 600;
      text-align: left;
      margin-bottom: 10px;
      padding: 3px 0 3px 35px;
      background: url('../images/icon-warning.png') 0 0 no-repeat;
    }
  }

  &-white {
    background-color: #fff;
    border: 1px solid #ddd;
  }

  &-yellow {
    background-color: @theme-color-1;
    border: 1px solid #d6e9c6;
  }

  &-green {
    background-color: #3c763d;
    color: #fff;
    .block-title {
      color: inherit;
    }
  }

  &-orange {
    background-color: @theme-color-2;
  }
  &-warning,
  &-red {
    background-color: #911;
    color: #fff;
    .block-title {
      color: inherit;
    }
  }
}
