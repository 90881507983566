// Basic Components

.overlay {
  display: none;

  &.shadow {
    top: -56px;
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.33);
    z-index: 1000;

    @media @normal {
      display: none;
    }
  }
}

.tammenu {
  position: relative;
  width: 100%;
  background: #f7dbcb;
  padding: 1rem;
  margin: 0 0 2em 0;
  color: #414141;
  border-radius: 6px;

  a {
    color: @theme-color-2;
  }

  @media @normal {
    padding: 0 @grid-gutter-width / 2;
    margin: 0;
  }
}

.mCustomScrollbar {
  width: 100%;
  position: relative;
}

.content-float-image {
  width: calc(~'100% - 4em');
  height: auto;
  margin: 1em 2em;
  max-width: 100%;

  @media @tablet {
    width: 50%;
    max-width: 320px;
  }

  @media @xwide {
    max-width: 400px;
  }
}

abbr[title],
acronym[title] {
  text-decoration: none;
}

// Recaptcha
.g-recaptcha {
  margin-bottom: 1rem;

  @media @normal {
    .scale(0.9);
    margin-left: -7px;
  }

  @media @wide {
    .scale(0.8);
    margin-left: -18px;
  }
}

// Common well
.well {
  .views-exposed-form {
    .views-exposed-widget {
      padding: 0;
      float: none;

      .form-item {
        padding: 0 @grid-gutter-width / 2;

        .form-item {
          margin: 0.25em 0;
          padding: 0;
          color: @black;

          a {
            padding: 0;
            margin: 0;
            font-size: @font-size-small;
            color: @black;

            &:hover {
              background: none;
              text-decoration: underline;
            }

            &.active {
              color: @theme-color-1;
            }
          }
        }
      }
    }
  }
}

// Nav Tabs
.nav {
  &.nav-tabs {
    margin-bottom: 10px;
    border-bottom-color: #ebebeb;
    > li {
      color: #6f6f6f;
      > a,
      > span {
        color: #6f6f6f;
        border-radius: 0;
        box-shadow: none;
        border: none;
        background-color: unset;
        &:hover {
          background-color: unset;
          box-shadow: none;
          border: none;
        }
      }

      &:hover {
        background-color: unset;
      }

      &.active {
        border-bottom: 2px solid #f58220;
        > a {
          color: #000;
          border-radius: 0;
          border: none;
          background-color: transparent;
        }
      }
    }
  }
}

.address-link {
  color: @link-color;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}
