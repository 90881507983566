.select-acc-list {
  .view-content {
    .select-acc-item {
      padding: @grid-gutter-width / 2;
      box-sizing: border-box;
      text-align: center;
      margin-bottom: 1em;
      border: 1px solid #d8d8d8;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
      width: 100%;

      &:hover {
        border-color: @theme-color-2;
      }

      .views-field-field-account-image {
        border: 1px solid @gray-lighter;

        img {
          margin: 0 auto;
          width: 100px;
          height: 100px;
          .object-fit(contain);
        }
      }

      .views-field-name {
        font-size: @font-size-small;
        margin-top: 1em;
        color: #000;
      }
    }
  }

  &.koda-view-smaller-listing {
    .view-content {
      flex-direction: column;

      .select-acc-item {
        padding: @grid-gutter-width / 2;
        box-sizing: border-box;
        flex: 1 1 100% !important;
        text-align: center;
        margin-bottom: 1em;

        .views-field-field-account-image {
          display: table-cell;
          vertical-align: middle;

          img {
            max-height: 80px;
            width: 120px;
            .object-fit(contain);
          }
        }

        .views-field-name {
          display: table-cell;
          padding-left: 1rem;
          vertical-align: middle;
        }
      }
    }
  }
}

// Account Selection
.view-account-selection {
  .view-five-columns;
  .view-content {
    display: grid;
    &::before {
      display: none !important;
    }
  }
}
