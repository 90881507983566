// Tracking Delivery Meter

.tracking-delivery-meter {
  position: relative;
  width: 100%;
  height: 80px;
  margin-bottom: 15px;
  background-repeat: no-repeat;
  background-position: center 0;
  background-size: 100% 80px;

  @media screen and (max-width: 480px) {
    height: 40px;
    background-size: 100% 40px;
  }

  @media @tablet {
    background-size: contain;
    height: 115px;
  }

  &.ordered,
  &.order-waiting-approve,
  &.order-waiting-push-out,
  &.order-received-invoice,
  &.order-approved,
  &.order-placed {
    background-image: url('../images/meter/mobile/meter-1.png');

    @media @tablet {
      background-image: url('../images/meter/meter-1.png');
    }
  }

  &.awaiting-stock {
    background-image: url('../images/meter/mobile/meter-2.png');

    @media @tablet {
      background-image: url('../images/meter/meter-2.png');
    }
  }

  &.quality-check-1 {
    background-image: url('../images/meter/mobile/meter-3.png');

    @media @tablet {
      background-image: url('../images/meter/meter-3.png');
    }
  }

  &.in-production {
    background-image: url('../images/meter/mobile/meter-4.png');

    @media @tablet {
      background-image: url('../images/meter/meter-4.png');
    }
  }

  &.folding-&-quality-check-2,
  &.folding--quality-check-2,
  &.folding---quality-check-2 {
    background-image: url('../images/meter/mobile/meter-5.png');

    @media @tablet {
      background-image: url('../images/meter/meter-5.png');
    }
  }

  &.awaiting-further-stock {
    background-image: url('../images/meter/mobile/meter-6.png');

    @media @tablet {
      background-image: url('../images/meter/meter-6.png');
    }
  }

  &.packing-in-progress {
    background-image: url('../images/meter/mobile/meter-7.png');

    @media @tablet {
      background-image: url('../images/meter/meter-7.png');
    }
  }

  &.ready-to-be-shipped {
    @media @tablet {
      background-image: url('../images/meter/meter-8.png');
    }
  }

  &.shipped {
    background-image: url('../images/meter/mobile/meter-9.png');

    @media @tablet {
      background-image: url('../images/meter/meter-9.png');
    }
  }

  // &.waiting-on-payment{
  // 	background-image: url('../../images/meter/mobile/meter-8.png');
  // 	@media @tablet{
  // 		background-image: url('../../images/meter/meter-8.png');
  // 	}
  // }
}

.delivery-meter-wrapper {
  position: relative;
  width: 100%;
  display: block;
  text-align: center;
  margin: 15px 0 30px 0;
  overflow: scroll;

  @media @tablet {
    overflow: auto;
    margin: 15px 0 15px 0;
  }

  .delivery-meter {
    .flex-container(row, wrap, flex-start, flex-start, flex-start);
    position: relative;
    width: 580px;
    margin: 0 auto;

    @media @tablet {
      width: 100%;
    }

    @media @normal {
      width: 90%;
    }

    .meter {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 11%;

      @media @normal {
        flex: 1;
        width: auto;
      }

      &.done {
        span {
          background-color: rgba(96, 179, 41, 1);
        }
      }

      span {
        position: relative;
        height: 30px;
        width: 100%;
        display: flex;
        flex-direction: row;
        background-color: rgba(254, 0, 0, 1);

        @media @normal {
          height: 60px;
        }

        &::after {
          position: absolute;
          right: 0;
          content: '';
          display: inline-block;
          width: 0;
          height: 0;
          border-style: solid;
          border-color: #fff #fff #fff transparent;
          border-width: 15px 0 15px 10px;

          @media @normal {
            border-width: 30px 0 30px 20px;
          }
        }

        &::before {
          position: absolute;
          left: 0;
          content: '';
          display: inline-block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 15px 0 15px 10px;
          border-color: transparent transparent transparent #fff;

          @media @normal {
            border-width: 30px 0 30px 20px;
          }
        }
      }

      label {
        position: relative;
        width: 100%;
        padding: 8px;
        font-size: 10px;
        line-height: 12px;
        font-weight: normal;

        @media @normal {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}
