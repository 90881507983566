// Address Book
html {
  .address-book {
    .default-addresses,
    .additional-addresses {
      width: 100%;
      .list-address-wrapper {
        background: #fff;
        border: 1px solid #d8d8d8;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
        border-radius: 0px 0px 20px 0px;
        padding: 2rem !important;
      }
    }
  }
}
