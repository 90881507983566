/*------------------------------------*\
  Help Page
\*------------------------------------*/

.page-taxonomy-term.vocabulary {
  &--help {
    .region-content {
      > #block-system-main {
        .two-cols-grid;
        &::before {
          display: none;
        }
        .term-listing-heading {
          grid-column-start: 1;
          grid-column-end: 1;

          @media @wide {
            grid-column-start: 1;
            grid-column-end: 3;
          }
        }
      }
    }

    .node-help-content {
      position: relative;
      height: 100%;
      background: #fff;
      border: 1px solid #d8d8d8;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
      border-radius: 0;
      padding: 1.5rem;

      .help-content {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      .field-name-field-embed-code {
        position: relative;
        padding-bottom: 60%;
        margin-bottom: 1rem;

        .vidyard-player-container {
          position: absolute;
          width: 100%;
          top: 0;
          left: 0;
        }

        iframe {
          border: 1px solid #d8d8d8;
          box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
        }
      }

      .field-name-title {
        h4 {
          margin-top: 0;
          font-weight: 600;
        }
      }
    }
  }
}
