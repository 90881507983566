// Main Layout Wrapper
.main-wrapper {
  .myanimated;
  display: flex;
  position: relative;
  width: 100vw;
  //height: 100vh;

  > .main-content {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
  }

  .trigger-close {
    display: inline-flex;
    position: relative;
    cursor: pointer;
    color: #000;
    padding: 2px;
    top: 0;
    left: 0;
    line-height: 1;
    text-decoration: none;
    &::before {
      //display: block;
      font-size: 2rem;
      content: '\f00d';
      line-height: 1;
      font-weight: normal;
      font-family: @font-family-awesome;
    }
  }
}
