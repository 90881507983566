// My Store

.page-my-store h1.page-header {
  font-size: 25px;
  font-weight: 500;
  line-height: 1.5;
  padding-bottom: 10px;
}

/** My Sotre breadcrumbs **/
.my-store-breadcrumbs {
  ul {
    list-style: none;
    display: flex;
    line-height: 25px;
    padding: 0;
    align-items: center;
  }

  li {
    padding-right: 10px;
    display: none;

    &:not(:first-child)::before {
      content: '\003E';
      color: #424242;
      margin-right: 10px;
      font-size: 20px;
    }

    &.breadcrumb--root {
      display: flex;
    }

    a {
      text-decoration: none;
      font-weight: 500;
      font-size: 17px;
    }
  }
}

// .page-my-store-local-store-inventory-report,
// .page-my-store-store-product-usage-report,
// .page-my-store-in-store-purchase,
// .page-my-store-store-products-management {
// 	li.breadcrumb--root {
// 		display: flex;

// 		a {
// 			color:  #ff9a2e;
// 			pointer-events: auto;
// 		}
// 	}
// }
.page-local-store-inventory-report li.breadcrumb--inventory-report,
.page-store-product-usage-report li.breadcrumb--product-usage,
.page-in-store-purchase li.breadcrumb--replenishment,
.page-store-products-management li.breadcrumb--store-manage {
  display: flex;

  a {
    color: #424242;
    pointer-events: none;
  }
}

.block-rww-store {
  h2.block-title {
    font-size: 14pt;
    font-weight: 400;
    text-align: center;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    line-height: 1.6;
    color: #424242;
  }

  a {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    i.fa {
      width: 18px;
      height: auto;
      max-height: 20px;
      margin-right: 5px;
      margin-bottom: 0;
    }
  }
}

/** My Store Buttons **/
.stock-management-block
  .views-row.product-item
  form
  > div
  > fieldset.collapsible
  > .panel-body.collapse
  .stock-popup-inner
  a.tam-store-popup-close,
.stock-management-block
  .views-row.product-item
  form
  > div
  > fieldset.collapsible
  > legend.panel-heading
  > a,
.stock-management-block button,
.store-products-management-page-main button {
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  padding: 0.25em 1em;
  color: #fff;
  border-radius: 3em;
  max-width: 100%;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  line-height: 16px;

  &:focus {
    outline: none;
  }
}

.stock-management-block button,
.store-products-management-page-main button {
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    margin-right: 3px;
  }
}

.stock-management-block
  .views-row.product-item
  form
  > div
  > fieldset.collapsible
  > legend.panel-heading
  > a,
.stock-management-block button {
  width: 188px;
}

.stock-management-block
  .views-row.product-item
  form
  > div
  > fieldset.collapsible
  > .panel-body.collapse
  .stock-popup-inner
  a.tam-store-popup-close,
.stock-management-block
  .views-row.product-item
  form
  > div
  > fieldset.collapsible
  > legend.panel-heading
  > a {
  background-color: #000;
  border: 1px solid #000;

  &:hover {
    opacity: 0.7;
  }
}

/* Store Products Management Top Logo */
#block-rww-store-rww-store-account-icon {
  position: absolute;
  top: 100px;
  right: 0;

  img {
    width: auto;
    max-height: 67px;
  }
}

@media @normal {
  #block-rww-store-rww-store-account-icon {
    top: 0px;
  }

  .page-my-store h1.page-header {
    font-size: 43px;
  }
}

/* Store Products Management Tabs */
.store-products-management-tabs {
  ul {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 40px 0;

    a {
      color: #737373;
      font-size: 25px;
      font-weight: 600;
      padding: 10px 0;
      margin-right: 15px;
      text-decoration: none;
      cursor: pointer;
      border-bottom: 5px solid transparent;

      &.active {
        color: #000;
        border-bottom-color: #ff9a2e;
      }
    }
  }
}

/* Store Products Management General Style */
.store-products-management-page-main,
.stock-management-block {
  display: none;

  &.active {
    display: block;
  }
}

.stock-management-block,
.store-products-management-page-main {
  .view-content {
    display: flex;
    flex-wrap: wrap;

    h3 {
      width: 100%;
    }
  }

  .views-row.product-item {
    width: 100%;
    padding: 20px;

    @media @normal {
      width: 50%;
    }

    @media @wide {
      width: 25%;
    }

    @media @xwide {
      width: 16.666%;
    }

    form,
    .field-content,
    .views-field {
      height: 100%;
    }

    form > div {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      align-items: center;
      text-align: center;
    }
  }
}

/* Store Products Management - Stock Management */
.stock-management-block {
  position: relative;

  .views-row.product-item {
    form > div {
      > fieldset {
        border: none;
        background: none;
        box-shadow: none;
        margin: 10px 0;

        > .panel-body {
          padding: 0;
          background: none;
        }

        .store-item-title,
        .store-item-desc {
          display: inline;
        }
      }

      > fieldset.collapsible {
        margin-bottom: 10px;
        position: initial;
        width: 100%;
        margin-top: auto;

        > legend.panel-heading {
          background: none;
          border: none;
          padding-bottom: 0;

          > a::before {
            content: '';
            width: 16px;
            height: 16px;
            background-image: url(../images/store-mgmt.svg);
            background-repeat: no-repeat;
            display: inline-block;
            vertical-align: middle;
            margin-right: 3px;
          }
        }

        > .panel-body.collapse {
          position: fixed;
          right: auto;
          left: 50%;
          top: 180px;
          transform: translateX(-50%);
          z-index: 10;
          display: none;
          background-color: #fff;
          width: 80vw;
          height: 65vh;
          max-width: 94%;
          border: 1px solid #d8d8d8;
          box-shadow: 0px 1.62868px 4.88605px rgba(0, 0, 0, 0.35);
          padding: 0;

          &.in {
            display: block;
          }

          .stock-popup-inner {
            max-height: calc(65vh - 120px);
            overflow: auto;
            display: block;

            div[id*='store-process-message'] {
              position: absolute;
              bottom: 48px;
              width: 100%;
              padding: 0;

              @media @tablet {
                position: sticky;
                bottom: 0;
              }

              > div {
                padding: 15px;

                &:nth-child(2) {
                  .alert;
                  .alert-success;
                  border-radius: 0;
                }
              }
            }

            a.tam-store-popup-close {
              position: absolute;
              bottom: 15px;
              right: 217px;
              margin-bottom: 0;
            }

            button.stock-update {
              position: absolute;
              bottom: 15px;
              right: 20px;
              margin-bottom: 0;
            }
          }

          .stock-popup-heading {
            color: #ff9a2e;
            font-weight: 600;
          }

          fieldset {
            border: 0;
            margin: 0;

            .panel-body {
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-top: 2px solid #f1f1f1;

              .store-item-info {
                flex: 0 0 300px;
                text-align: left;
                display: flex;
                flex-direction: column;
              }

              .form-item {
                flex: 0 0 auto;
                display: flex;
                align-items: center;
                margin: 0;

                label {
                  margin: 0;
                  padding: 0 8px;
                  line-height: 1.2;
                  text-align: left;
                }
              }
            }
          }
        }
      }
    }
  }
}

/** Store Products Usage Report **/
.view-local-store-inventory-report,
.view-local-store-product-usage-report {
  .view-filters {
    width: 100%;
  }
}

/** Store restock **/
.rww-product-restock-section {
  .store-stock-input {
    margin-bottom: 5px;
    .qty-input-up-to-date {
      width: 50px;
    }
  }

  button.stock_replenishment_qty_update {
    padding: 2px 16px;
    font-size: 16px;
    background: #ff9a2e;
    border: 1px solid #ff9a2e;
    font-weight: 600;
    color: #ffffff;
    border-radius: 3px;
    margin: 0 10px;
  }

  .stock_replenishment_allocate_date {
    display: block;
  }

  .update-stock-result-message {
    display: block;
    color: #ff9a2e;
  }
}

form#store-product-based-reporting-form {
  > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > .btn.form-submit {
      margin: 0;
    }

    .table-responsive {
      width: 100%;
      margin-top: 1rem;
    }
  }

  .container-inline-date {
    margin-right: 10px;
    > .form-item {
      display: flex;
      flex-direction: row;
      margin-bottom: 0;
      > label {
        flex: 1;
      }
      .form-text {
        width: 100px;
      }
    }
  }

  .report-shortcut {
    display: inline;
    width: 100%;
    margin-bottom: 1rem;
    a {
      font-weight: 600;
      text-decoration: underline;
    }
  }

  .form-item {
    flex: 0 0 100%;
    width: 100%;
    display: inline-flex;
    > label {
      margin-bottom: 0;
      width: 160px;
    }
  }

  .form-control {
    &.form-select {
      width: auto;
    }
  }
}
