// Sidebar

.sidebar {
  padding: 0;

  // Left
  &__left {
    //.myanimated;
    transition: width 0.4s, height 1s;
    position: fixed;
    z-index: 10;
    height: 100%;
    left: 0;
    top: 0;
    padding-top: 80px;
    border: 1px solid #d8d8d8;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
    max-width: 250px;
    background-color: #fff;

    @media @wide {
      position: relative;
      width: 300px;
      height: auto;
      padding-top: 0;
    }

    .content-sidebar {
      width: 100%;
      position: sticky;
      top: 80px;
      height: 90vh;
      overflow-y: scroll;
      //.hide-scrollbar;
    }

    .region-sidebar-left {
      display: flex;
      flex-direction: column;
      padding: 1rem;
    }

    // Responsive
    @media @wide {
      position: relative;
      border: none;
      box-shadow: none;
    }

    // If toggle
    &.toggle {
      @media @wide {
        width: 80px;
      }
      .menu__link,
      .u-menu,
      .u-list {
        padding-left: 35px;
        &::before {
          position: absolute;
        }
      }
      .u-menu-m {
        &::before {
          display: none;
        }
      }

      // Filter Function
      @media screen and (max-width: @screen-lg-min) {
        .sidebarNavFilter;
      }

      // Account Name
      #selected-account,
      #selected-account-name {
        .u-account-m,
        .u-account {
          display: none;
        }

        @media @wide {
          .u-account-m {
            display: flex;
          }
          #change-account,
          .u-account {
            display: none;
          }
        }
      }
      // Staff Name
      #selected-staff {
        display: flex;
        @media @wide {
          display: none;
        }
      }
      // Responsive
      @media @wide {
        .menu__link,
        .u-menu {
          .hide-icon-text;
        }
        .u-list {
          display: none;
        }
        .u-menu-m {
          .hide-icon-text;
          &::before {
            display: flex;
          }
        }
      }
    }

    // If Not Toggle
    &:not(.toggle) {
      // Menu
      .menu__link,
      .u-menu {
        .hide-icon-text;
        @media @wide {
          .show-icon-text;
        }
      }
      // List
      .u-list {
        display: none;
        @media @wide {
          .show-icon-text;
          display: block;
        }
      }

      // Nav Filter
      @media @wide {
        .sidebarNavFilter;
      }

      // Account
      #selected-account,
      #selected-account-name {
        .u-account-m {
          display: flex;
        }
        #change-account,
        .u-account {
          display: none;
        }
        @media @wide {
          .u-account-m {
            display: none;
          }
          #change-account,
          .u-account {
            display: flex;
          }
        }
      }
      // Staff
      #selected-staff {
        display: none;
        @media @wide {
          display: flex;
        }
      }
    }
  }
}

#selected-account-name,
#selected-staff {
  .myanimated;
}

// Nav Filter
.sidebarNavFilter {
  // Filter
  .item-list {
    > .menu {
      > li {
        > .item-list {
          &:not(.open) {
            display: none;
          }
        }
      }
    }
  }

  .u-list {
    //display: none;
    // has Children
    &.has-children {
      cursor: pointer;
      position: relative;
      &:hover {
        text-decoration: underline;
      }
      &::after {
        .myanimated;
        content: '\f107';
        position: absolute;
        font-family: @font-family-awesome;
        right: 30%;
        top: 0;
        line-height: 3;
        width: 15px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
      }

      &.active-open {
        &::after {
          .myanimated;
          transform: rotate(-180deg);
        }
      }
    }
  }
}
