// Cart

.page-cart {
  #cart-form-pane {
    padding: 1rem;
    background: #ffffff;
    border: 1px solid #d8d8d8;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 0px 0px 20px 0px;
  }

  .desc,
  .product-description {
    font-weight: normal;
    font-size: 16px;
    * {
      font-size: inherit;
    }
  }

  .remove {
    button {
      background: none;
      border: none;
      color: @gray;
      font-size: 0;

      .icon {
        font-size: @font-size-base;
      }
    }
  }

  td.qty {
    input {
      width: 50px;
      text-align: center;
      padding: 4px;
      color: #555555;
    }
  }

  #user-group-uc-cart-view-form {
    .sticky-table {
      tbody {
        tr {
          &:nth-last-child(2) {
            font-size: @font-size-small;

            .group-cart-desc-exceed-budget {
              font-size: @font-size-small;
            }
          }

          &:nth-last-child(1) {
            font-size: @font-size-large;
            color: @gray-base;
          }
        }
      }
    }

    .entity-uc-cart-item {
      display: none;
    }

    .form-actions {
      text-align: right;
      display: flex;
      justify-content: flex-end;
      > * {
        display: inline-block;
        .btn;
        margin: 0 0 0 0.5em;
        padding: 0.25em 1em;
      }

      > a {
        &:first-child {
          margin-right: auto;
          text-decoration: none;
          &::before {
            content: '\f104';
            margin-right: 8px;
            font-family: @font-family-awesome;
          }
        }
      }

      .btn {
        font-weight: normal;
        &:hover {
          background-color: @theme-color-2 !important;
          border-color: @theme-color-2 !important;
          color: #fff !important;
        }
        &.btn-info {
          background-color: #000;
          color: #fff;
          border-color: #000;
          .icon {
            display: none;
          }
        }
      }

      .btn-info {
        .graybtn;
      }
    }
  }

  #subtotal-title {
    font-weight: normal;
  }
}

// Cart : pop-up

#cboxLoadedContent {
  margin-bottom: 0;
}

.cart-popup-form {
  color: #000;
  .nologo-chbx,
  .withlogo-chbx {
    display: flex;
    align-items: center;
    input {
      order: 0;
      display: inline-block;
      margin: 0 0.5rem 0 0;
      border: 1px solid #414141;
    }
    span {
      order: 1;
    }
  }
  textarea {
    width: 100%;
  }
  button {
    .btn;
    margin-bottom: 0;
  }
}

.tam-free-shipping-info {
  margin-bottom: 2rem;
}

// Both Cart and Checkout Tables
#cart-form-pane,
#uc-cart-checkout-form {
  table.table {
    border-collapse: inherit;
    tr {
      border-bottom: none;
      > td {
        vertical-align: middle !important;
        img {
          margin: 0 auto;
        }
        &.subtotal {
          font-weight: normal;
          .uc-price {
            font-size: 25px;
            color: #555555;
          }
        }
        .form-type-uc-quantity {
          display: flex;
          justify-content: center;
        }
      }
    }

    tbody.account {
      > tr {
        &.staff-item {
          .myanimated;
          > td {
            border-bottom: none;
            border-top: none;
            &:not(:first-child) {
              border-left: 1px solid #ddd;
            }
            &.price {
              font-size: 16px;
            }
            &.qty {
              .form-control {
                height: 25px;
              }
            }
            &.remove {
              width: 5%;
              margin: 0;
              text-align: center;
              button,
              .btn {
                padding: 0;
                margin: 0 auto;
                .icon {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }

      .cart-user-warning {
        background-image: unset;
        padding: 8px 20px;
      }

      > .account-header {
        > td {
          border-bottom: 1px solid #ddd;
          &:first-child {
            font-size: 18px;
            font-weight: 600;
            cursor: pointer;
          }
        }
        > .account-name {
          width: 30%;
          > .account {
            padding-left: 40px;
            font-weight: normal;
            color: #555;
            font-size: 14px;
          }
          .user {
            a {
              color: inherit;
            }

            &::before {
              font-family: @font-family-awesome;
              content: '\f068';
              margin-right: 1rem;
              font-size: 14px;
              font-weight: normal;
              color: @theme-color-2;
              border: 1px solid @theme-color-2;
              padding: 4px;
              line-height: 1;
            }
          }
        }
        > .empty-cart-desc {
          width: 10%;
        }
        > .empty-cart-desc {
          width: 50%;
        }
        > .subtotal {
          width: 5%;
        }

        td {
          &:last-child {
            width: 5%;
          }
        }
      }

      // Not Open
      &:not(.open) {
        > .account-header {
          > td {
            &.account-name {
              > .user {
                &::before {
                  content: '\f067';
                }
              }
            }
          }
        }
        > tr.staff-item {
          display: none;
        }
      }
    }
  }
}

// Checkout Table
form {
  &[id*='uc-cart-checkout-form'] {
    .cart-review {
      thead {
        th {
          &.image {
            width: 20%;
          }
          &.products {
            width: 70%;
            a {
              color: @theme-color-2;
            }
          }
          &.qty {
            width: 5%;
          }
          &.price {
            width: 5%;
          }
        }
      }
      .account-header {
        .cart-user-warning {
          margin-top: 10px;
        }
      }
    }

    tbody.account {
      &:not(.open) {
        > tr.account-header {
          > td {
            > .user {
              &::before {
                content: '\f067';
              }
              > a {
                &:hover {
                  color: @theme-color-2;
                }
              }
            }
            .account,
            .cart-user-warning {
              display: none;
            }
          }
        }
      }

      > tr.account-header {
        width: 100%;

        > td {
          &:first-child {
            color: #414141;
            font-size: 16px;
          }
          > .user {
            &::before {
              font-family: @font-family-awesome;
              content: '\f068';
              margin-right: 1rem;
              font-size: 14px;
              font-weight: normal;
              color: @theme-color-2;
              border: 1px solid @theme-color-2;
              padding: 4px;
              line-height: 1;
            }
            > a {
              &:hover {
                color: @theme-color-1;
              }
            }
          }
          .account {
            padding-left: 40px;
            font-weight: normal;
            color: #555;
            font-size: 14px;
          }
          > .user {
            a {
              color: inherit;
            }
          }
          > .cart-user-warning {
            margin-top: 8px;
            background-image: unset;
            padding-left: 1rem;
            line-height: 1.5;
          }
        }

        .price {
          vertical-align: initial !important;
          span {
            width: 100%;
            float: left;
            &.uc-price {
              color: #414141;
              font-size: 16px;
            }
            &#subtotal-title {
              display: none;
            }
          }
        }
      }
      // Staff Item
      > tr.staff-item {
        > td {
          border-left: none !important;
          color: #000;
          &.image {
            width: 20%;
          }
          &.products {
            width: 70%;
            font-weight: 600;
            line-height: 1.2;
            ul {
              padding-left: 18px;
            }
          }
          &.qty {
            width: 5%;
          }
          &.price {
            width: 5%;
          }
          &.uc-price {
            font-style: italic;
          }
        }
      }
    }
  }
}
