/*------------------------------------*\
  Listings
  - Triggers Grid Change if sidebar exist
\*------------------------------------*/
html body {
  &.has-offset-sidebar {
    .koda-view-bigger-listing {
      .view-three-columns;
    }
  }

  &:not(.has-offset-sidebar) {
    .koda-view-bigger-listing {
      .view-five-columns;
    }
  }
}

.koda-view-bigger-listing {
  .view-content {
    &::before {
      display: none !important;
    }

    > h3 {
      margin: 0;
      font-size: 26px;
      font-weight: 400;
    }

    .views-row {
      .myanimated;
      background: #ffffff;
      border: 1px solid #d8d8d8;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
      border-radius: 0px 0px 20px 0px;
      padding: 1.5rem;
      height: 100%;

      &.select-acc-item {
        border-radius: 0;
      }
    }
  }

  .view-switch {
    .view-switch-block {
      color: @theme-color-2;
    }
  }
}

// Smaller
.koda-view-smaller-listing {
  .view-content {
    flex-direction: column;
    flex-wrap: nowrap;
    display: flex;
    padding: 1.5rem;
    background: #ffffff;
    border: 1px solid #d8d8d8;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 0px 0px 20px 0px;

    > h3 {
      margin: 15px 0;
      font-size: 26px;
      font-weight: 400;
    }
  }

  .view-switch {
    .view-switch-list {
      color: @theme-color-2;
    }
  }
}

// Term Listing
.koda-term-listing {
  .view-four-columns;
}

/*------------------------------------*\
 Product Listing Block 
 - extend block-system-main view 
\*------------------------------------*/
.koda-product-listings {
  #block-system-main;
}
