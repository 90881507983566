// Sidebar My Account Menu

.my-acc-menu {
  .block-title {
    display: none;
  }
  ul.menu {
    position: relative;
    list-style: none;
    padding-left: 0;
    > li {
      font-weight: 600;
      color: @gray-base;
      line-height: 2;
      div,
      span,
      a {
        position: relative;
        color: inherit;
        font-weight: inherit;
      }
      ul {
        list-style: none;
        padding-left: 0;
        > li {
          font-weight: normal;
          * {
            font-weight: normal;
          }
        }
      }
    }
  }
}

// User Menu
.u-menu,
.u-menu-m,
.u-list {
  position: relative;
  display: flex;
  &::before {
    left: 0;
    font-weight: normal;
    color: #8c8c8c;
    font-size: 16px;
    font-family: @font-family-awesome;
  }
}

.u-menu,
.u-menu-m {
  position: relative;
  width: 100%;
  margin-bottom: 5px;
  color: @gray-base;
  font-weight: 600;
}

.u-menu {
  &.dashboard {
    &::before {
      content: url('../images/icon-dashboard.svg');
    }
  }
  &.my-store {
    &::before {
      content: '\f553';
    }
  }
  &.logout {
    &::before {
      content: url('../images/icon-logout.svg');
    }
  }
  &.place-order {
    &::before {
      content: '\f46c';
    }
  }
  &.orders {
    &::before {
      content: '\f46d';
    }
  }
  &.sub-groups {
    &::before {
      content: '\f500';
    }
  }
  &.order-report {
    &::before {
      content: '\f737';
    }
  }
  &.order-approval {
    &::before {
      content: '\f017';
    }
  }
  &.returns-policy {
    &::before {
      content: '\f1c1';
    }
  }
  &.order-cancellation-policy {
    &::before {
      content: '\f1c1';
    }
  }
}

.u-list {
  &.my-account {
    &::before {
      content: '\f007';
    }
  }
  &.my-orders {
    &::before {
      content: '\f291';
    }
  }
  &.my-reports {
    &::before {
      content: '\f659';
    }
  }
  &.help {
    &::before {
      content: '\f05a';
    }
  }
}

.u-menu-m {
  @media @wide {
    &::before {
      display: none;
    }
  }
  &.edit-account {
    &::before {
      content: '\f4ff';
    }
  }
  &.address-book {
    &::before {
      content: '\f279';
    }
  }
  &.staff-listing {
    &::before {
      content: '\f500';
    }
  }
  &.view-my-orders {
    &::before {
      content: '\f291';
    }
  }
  &.order-history {
    &::before {
      content: '\f1da';
    }
  }
  &.return-order {
    &::before {
      content: '\f33d';
    }
  }
  //
  &.total-spend-by-employee {
    &::before {
      content: '\f4b9';
    }
  }

  &.overal-product-usage {
    &::before {
      content: '\f3fd';
    }
  }

  &.product-usage-by-employee {
    &::before {
      content: '\f200';
    }
  }

  &.budget-remaining-report {
    &::before {
      content: '\f080';
    }
  }

  &.job-department-report {
    &::before {
      content: '\f201';
    }
  }

  &.return-order {
    &::before {
      content: '\f49e';
    }
  }

  &.view-all-orders {
    &::before {
      content: '\f468';
    }
  }
}
