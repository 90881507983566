// For this page only - we set the region-content to be flex : Don't stress! We don't need to apply this to the whole page as it was requeted after the site is structured

.page-ppe-products {
  .region-content {
    display: flex;
    flex-wrap: wrap;
    > #block-system-main {
      flex: 1;
    }

    > #block-system-main {
      order: 1;
    }
    > .block-simple-taxonomy-menu {
      margin-right: 2rem;
      order: 0;
    }
  }
}
