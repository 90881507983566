/*------------------------------------*\
  View
\*------------------------------------*/
.view {
  .view-filters {
    margin-bottom: 1rem;
    .view-header {
      margin-bottom: 0;
    }
    form {
      flex: 0 0 auto !important;
    }
    .btn {
      margin-bottom: 0;
    }
  }

  .view-header {
    margin-bottom: 1rem;
    padding: 0;
  }

  .view-content {
    width: 100%;
    .views-row {
      .node-teaser {
        height: 100%;
      }
    }
  }

  .view-footer {
    margin-top: 2rem;
  }
}

.view-five-columns {
  .view-content {
    .five-cols-grid;
  }
}

.view-four-columns {
  .view-content {
    .four-cols-grid;
  }
}

.view-three-columns {
  .view-content {
    .three-cols-grid;
  }
}

.view-two-columns {
  .view-content {
    .two-cols-grid;
  }
}

.two-cols-grid {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  > h3,
  > h2 {
    grid-column-start: 1;
    grid-column-end: 1;
  }
  @media @wide {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    > h3,
    > h2 {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
}

.three-cols-grid {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  > h3,
  > h2 {
    grid-column-start: 1;
    grid-column-end: 1;
  }

  @media @normal {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    > h3,
    > h2 {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  @media @xwide {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    > h3,
    > h2 {
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }
}

.four-cols-grid {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  > h3,
  > h2 {
    grid-column-start: 1;
    grid-column-end: 1;
  }
  @media @normal {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    > h3,
    > h2 {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
  @media @xwide {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    > h3,
    > h2 {
      grid-column-start: 1;
      grid-column-end: 5;
    }
  }
}

.five-cols-grid {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  > h3,
  > h2 {
    grid-column-start: 1;
    grid-column-end: 1;
  }
  @media @tablet {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    > h3,
    > h2 {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
  @media screen and (min-width: 1440px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    > h3,
    > h2 {
      grid-column-start: 1;
      grid-column-end: 5;
    }
  }
  @media @xwide {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    > h3,
    > h2 {
      grid-column-start: 1;
      grid-column-end: 6;
    }
  }
}

#block-system-main {
  .view-switch {
    text-align: right;
    .btn-view-switch {
      border: none;
      padding: 0;
      margin: 0 0 0 0.5rem;
      display: inline-flex;
    }
  }

  .view-filters {
    background: @gray-lightest;
    padding: 0;
    display: flex;
    align-items: left;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;

    @media @tablet {
      align-items: center;
      flex-direction: row;
    }

    > * {
      flex: 1 1 auto;
      order: 3;
    }

    > .view-header {
      order: 2;
    }

    > form {
      order: 1;
    }

    .views-exposed-form {
      .views-exposed-widget {
        float: none;
        display: inline-flex;
        text-align: left;

        .btn {
          margin: 0;
        }

        label {
          display: none;
        }

        .form-text {
          max-width: 355px;
        }
      }
    }

    .view-header {
      .btn {
        &:before {
          display: inline-block;
          padding-right: 0.5em;
          font-family: @font-family-awesome;
          content: '\f067';
        }
      }
    }
  }
}

.view-uc-orders-user {
  .views-field-order-id {
    img {
      display: inline-block;
      margin: 0 0.25em;
      padding: 0;
      top: 0;
    }
  }
}

#sort-table {
  th.active {
    img {
      display: inline-block;
      margin: -0.25em 0 0 0.5em;
    }
  }
}

// Taxonomy
.view-taxonomy-term {
  .view-header {
    .view-taxonomy-term-page {
      .clearfix;
      padding-bottom: 2em;
      position: relative;

      .views-field-field-account-image {
        display: flex;
        width: 100%;
        padding: 10px;

        img {
          width: 100%;
          max-width: 100px;
          margin: 0 auto;
        }
      }

      .views-field-description {
        width: 100%;
        float: left;

        @media @tablet {
          width: 62%;
        }

        @media @xwide {
          width: 70%;
        }
      }
    }
  }
}

.views-field-field-account-image {
  .field-content.pull-right {
    float: none !important;

    @media @normal {
      float: right !important;
    }
  }
}
