// Products Mixin
.productItem {
  .tam {
    &_img,
    &_desc,
    &_title,
    &_category,
    &_linked_logo_names_display {
      display: flex;
      > div {
        font-size: #414141;
        font-size: 16px;
        &.label {
          color: #414141;
          font-weight: 600;
          border-radius: 0;
          padding-left: 0;
        }
      }
    }

    &_desc {
      font-size: 24px;
      text-align: left;
      margin-bottom: 1rem;
      a {
        color: #000;
        line-height: 1.2;
        font-weight: 600;
        text-decoration: none;
        &:hover {
          text-decoration: none;
        }
      }
    }

    &_img {
      margin-bottom: 1rem;
      > a {
        width: 100%;
      }
      img {
        margin: 0 auto;
      }
    }

    &_linked_logo_names_display {
      flex-direction: column;
      text-align: left;
      .label {
        text-align: left;
      }
    }
  }

  form {
    .product-extra {
      width: 100%;
    }
  }

  .form-item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    .form-control {
      color: #000;
      flex: 1;
      margin-left: 15px;
    }

    .control-label {
      font-weight: normal;
      margin: 0;
      display: flex;
      align-items: center;
      padding-right: 1rem;
      width: 90px;
    }
  }

  .out-of-stock {
    display: inline;
    padding: 2px 4px;
    background-color: #7dc323;
    color: #fff;
    font-size: 14px;
  }

  .form-type-uc-quantity {
    display: flex;
    flex-wrap: wrap;
    width: 55%;
    margin-bottom: 0;
    align-items: center;

    span {
      font-size: 14px;
    }
  }

  .form-actions {
    position: relative;
    width: auto; //width: 45%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;

    @media screen and (max-width: 460px) {
      width: 100%;
      margin-top: 15px;
      > button {
        width: 100%;
      }
    }

    button {
      margin: 0;
      .icon {
        display: none;
      }
    }

    .uc-ajax-cart-alt-status-messages {
      position: absolute;
      width: 388px;
      bottom: 0;
      right: 0;
      z-index: 50;
      > .alert {
        margin-bottom: 0;
      }
    }
  }

  .add-to-cart-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: #fafafa;

    .magical-modal-link {
      display: flex;
      align-items: center;
      padding-left: 8px;
    }
  }

  .add-to-cart {
    background: @gray-lightest;
    padding: @grid-gutter-width;
    margin-top: 1rem;

    .form-actions {
      position: relative;

      .logo-over-addtocart-button {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 10;
        position: absolute;
        display: block;
      }
    }
  }

  .product-extra {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 0;

    .form-item {
      margin-bottom: 1rem;
    }

    // Stock Info
    &.product-extra-tam_stock_info {
      width: 50%;
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      > div {
        background-color: #7dc323;
        color: #fff;
        padding: 2px 10px;
      }
    }

    &.product-extra-tam_stock_price {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 1rem;
      margin-left: auto;
      width: 50%;

      &.no-stock {
        .display-price {
          color: transparent;
        }
      }

      .display-price {
        padding: 0;
        color: @theme-color-2;
        font-size: 25px;
        font-weight: 600;

        &.no-stock {
          color: transparent;
        }

        .uc-price {
          &::after {
            content: 'ex GST';
            font-size: 12px;
            margin-left: 8px;
          }
        }
      }
    }
    // Hide unescessary field
    &.product-extra-tam_img,
    //&.product-extra-tam_title,
    &.product-extra-tam_stock_info_name,
    &.product-extra-tam_stock_info_uuid,
    &.product-extra-tam_stock_info_notification,
    &.product-extra-tam_stock_info_weight,
    &.product-extra-tam_stock_info_weight,
    &.product-extra-selected_sku,
    &.product-extra-tam_pack_size,
    //&.product-extra-tam_logo_0,
    &.product-extra-linked_logo_names,
    &.product-extra-tam_logo_name {
      display: none;
    }

    // 100%
    &.product-extra-tam_colour,
    &.product-extra-tam_size {
      width: 100%;
      > .form-item {
        width: 100%;
      }
    }
  }
}

// Product/s List
.product-item {
  padding: 1em @grid-gutter-width / 2;
  background: @white;
  .myanimated;
  &:hover {
    background: #fffeda;
  }

  .node-product {
    .field-name-uc-product-image {
      img {
        margin: 0 auto;
        height: 160px;
        width: auto;
        max-width: 100%;
        .object-fit(contain);
      }
    }
    .field-name-title {
      text-align: center;

      h3 {
        font-size: @font-size-large;
        font-weight: 600;
        margin: 0.5em 0 0.25em 0;
      }
    }
    .field-name-field-stockitems-web-description {
      text-align: center;
    }
  }

  .product-extra-tam_img {
    img {
      margin: 0 auto;
    }
  }

  .product-extra-tam_title {
    text-align: center;
    font-size: 25px;
    font-weight: 500;
    &::before {
      content: 'SKU :';
      font-weight: 600;
      margin-right: 4px;
    }
  }

  .product-extra-tam_desc {
    height: auto !important;
    text-align: left;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #414141;
    min-height: 64px;
    line-height: 1;
    a {
      color: inherit;
    }
  }

  label {
    float: left;
    width: 40%;
  }

  .product-extra-tam_stock_stock {
    float: left;
    width: 40%;
  }

  .product-extra-tam_stock_price {
    float: left;
    width: 40%;

    .display-price {
      clear: both;
      text-align: left;
      float: none;
      padding: 0;
    }
  }

  .product-extra-tam_logo_name {
    padding-bottom: 0.5rem;
    float: right;
    width: 60%;
    font-size: @font-size-small;
    img {
      width: 32px;
      height: auto;
    }
  }

  .product-extra-tam_colour {
    float: left;
    width: 100%;
    clear: both;
    margin-bottom: 10px;
  }

  .product-extra-tam_size {
    float: left;
    width: 100%;
    clear: both;
    margin-bottom: 10px;
  }

  .form-type-uc-quantity {
    float: left;
    width: 100%;
    clear: both;
    margin-bottom: 10px;
  }

  .form-control {
    float: right;
    width: 60%;
  }

  .form-actions {
    text-align: center;
    padding: 10px 0;
  }
}

// View

.view-products {
  .view-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;

    > h3 {
      flex: 1 1 100%;
    }

    .product-item {
      .node-product {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }

  .view-header {
    background: @gray-lightest;
    padding: 1em @grid-gutter-width / 2;
    display: none;
    @media @tablet {
      display: block;
    }
    .view-switch {
      text-align: right;
    }
  }

  &.koda-view-smaller-listing {
    .view-content {
      flex-direction: column;
      flex-wrap: nowrap;

      .product-item {
        flex: 1 1 100%;
        width: 100%;
        padding: 0.5em 1rem;

        .node-product {
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;

          > .field {
            flex: 1 1 auto;
            width: 0;
            padding: 0 @grid-gutter-width / 4;
          }

          .field-name-uc-product-image {
            flex: 0 0 90px;
            padding-right: 1rem;

            img {
              margin: 0;
              min-width: 70px;
              width: 70px;
              max-width: 70px;
              height: 100px;
              .object-fit(contain);
            }
          }

          .field-name-title {
            text-align: left;
            flex: 0 0 20%;

            h3 {
              font-size: @font-size-base;
              font-weight: 600;
              margin: 0;
            }
          }

          .field-name-field-stockitems-web-description {
            flex: 1 1 100%;
            text-align: left;
          }
        }
      }
    }
  }
}

.cart-user-warning {
  display: inline-block;
  font-size: 15px;
  font-weight: normal;
  padding: 0.5rem 1rem 0.5rem 3rem;
  border-radius: 6px;
  background-color: #f7dbcb;
  background-image: url('../images/icon-warning.png');
  background-repeat: no-repeat;
  background-position: 15px 12px;
  .cart_show_user_budget {
    color: #f58220;
  }
}
