// Node Full
.node-product {
  &.view-mode-full {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    padding: 2rem;
    background: #ffffff;
    border: 1px solid #d8d8d8;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 0px 0px 20px 0px;

    .model {
      font-weight: normal;
      .field-label {
        font-weight: 600;
      }
    }
    .field {
      .field-label {
        font-weight: 600;
      }
    }

    .productItem;

    .add-to-cart {
      width: 100%;
      margin: 20px 0;
      border: none !important;
      form {
        > div {
          display: flex;
          flex-wrap: wrap;
        }
      }
    }

    .product {
      &__left {
        .field-name-uc-product-image {
          a {
            img {
              margin: 0 auto;
            }
          }
        }
      }

      &__right {
        display: inline;

        .product-info.model,
        .field-name-field-product-category,
        .field-name-field-stockweb-sales-html {
          width: 100%;
        }

        > .product-info {
          &.display-price {
            display: none;
          }
          .product-info-label {
            font-weight: 600;
          }
        }

        .add-to-cart {
          padding: 0;
          background: unset;
          form {
            border-radius: 4px;
            .display-price,
            .pass-data.btn {
              display: none;
            }
            .product-extra {
              .display-price {
                display: flex;
              }
            }
            .form-type-uc-quantity {
              width: 100%;
            }
            .btn {
              margin-bottom: 0;
              .icon {
                display: none;
              }
            }
            .pass-data {
              margin-left: 15px;
            }
            .form-actions {
              width: 100%;
              margin-top: 15px;
              margin-left: 0;
            }
            .magical-modal-link {
              margin-left: 10px;
            }
          }
        }
      }
    }

    @media @normal {
      flex-direction: row;
      .product {
        &__left {
          flex: 0 0 65%;
          padding-right: 4rem;
        }

        &__right {
          flex: 1;

          .add-to-cart {
            form {
              .form-type-uc-quantity {
                width: 35%;
              }
              .form-actions {
                width: auto;
                margin-top: 0;
                margin-left: 15px;
              }
            }
          }
        }
      }
    }
  }
}
