/*
======================================================
Card Checkout form
======================================================
*/
.page-cart-checkout {
  form {
    &.uc-cart-checkout-form {
      position: relative;
      width: 100%;
      background: #ffffff;
      border: 1px solid #d8d8d8;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
      border-radius: 0px 0px 20px 0px;
      padding: 2rem;

      > div {
        position: relative;
        width: 100%;
        .three-cols-grid;
        > * {
          margin: 0;
          width: 100%;
        }
      }

      .wrap-js {
        position: relative;
        display: flex;
        flex-direction: column;
        color: #727272;
        font-size: 14px;
        overflow: auto;
        &__header {
          position: relative;
          width: 100%;
          margin-bottom: 1rem;
          h2 {
            margin-top: 0;
            font-size: 18px;
            font-weight: 600;
            color: #2e2e2e;
          }
        }
      }

      .table-responsive {
        @media all and (max-width: 767px) {
          width: calc(~'85vw - 50px') !important;
        }
        @media all and (max-width: 640px) {
          width: 70vw !important;
        }
        table {
          width: 100% !important;
        }
      }

      .panel {
        position: relative;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 0 !important;
        border-radius: 0;
        border: none;
        box-shadow: none;

        .panel-heading {
          padding: 0;
          background: unset;
          border: none;
        }
        .panel-body {
          position: relative;
          width: 100%;
          padding-left: 0;
          padding-right: 0;
        }
      }

      legend {
        font-weight: normal;
        line-height: 1;
      }

      .form-actions {
        display: flex;
        flex-direction: column;
        .btn {
          border-radius: 0;
          margin-right: 0;
          background-color: #77b726;
          border-color: #77b726;
          color: #fff;
          outline: none;
          padding: 0.5rem 1rem;
          &:active {
            outline: none;
          }
          &:hover {
            background-color: darken(#77b726, 5%);
          }
          &[value*='Cancel'] {
            background-color: #000;
            border-color: #000;
            .icon {
              display: none;
            }
            &:hover {
              background-color: @theme-color-1;
              border-color: @theme-color-1;
              color: #000;
            }
          }
        }
      }
    }
  }

  .block-tam-shipping {
    position: absolute;
    top: -1.5rem;
    width: 100%;
    padding: 0.25rem 1rem;
    color: @white;
    background: @theme-color-1;
  }

  #user_group_cart-pane {
    height: 100vh;
    overflow-y: auto;
  }

  #delivery-pane-body,
  #billing-address-pane {
    .control-label {
      font-weight: 600;
    }
    .address-pane-table {
      table {
        width: 100%;
        tbody {
          display: grid;
          grid-gap: 1rem;
          @media @tablet {
            grid-template-columns: repeat(2, minmax(0, 1fr));
          }
          tr {
            display: flex;
            flex-direction: column;
            text-align: left;
          }
          .field-label {
            text-align: left;
            font-weight: normal;
          }
        }
      }
    }
  }

  .field-delivery_zone {
    .control-label {
      display: none;
    }
  }

  #line-items-div {
    background-color: #f9f8e7;
    border-radius: 6px;
    width: 100%;
    border: none;
    margin: 0 0 1rem 0;
    padding: 1rem;
    table {
      width: 100%;
      font-size: 16px;
      color: #2e2e2e;
      tr {
        &.line-item-total {
          border-top: 1px solid #d9d9d9;
          > td {
            font-weight: 600;
          }
        }
        td {
          &.title {
            font-weight: normal;
          }
        }
      }
    }
  }

  #payment-details {
    border: none;
  }

  #edit-field-po-number,
  #edit-field-cc-mail,
  #comments-pane {
    .control-label {
      font-weight: 600;
    }
  }

  .order-review-table {
    margin-top: 2rem;
    width: 100%;
    .cart-review {
      margin-bottom: 0;
    }
    .title-col {
      padding-left: 0;
      padding-right: 15px;
    }
    .field-name-field-po-number {
      display: flex;
      justify-content: center;
    }
    .account-header {
      > .price {
        span {
          width: 100%;
          float: left;
        }
      }
      .user,
      .account{
        font-weight: bold;
      }
      .cart-user-warning{
        display: none;
      }
    }

   

    #uc-cart-checkout-review-form {
      text-align: center;
      .wrap-js__header {
        width: 100%;
        text-align: center;
        h2 {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
      .form-actions {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
      }
    }
  }
}
