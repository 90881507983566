// Taxonomy Menu

.block-simple-taxonomy-menu {
  .myanimated;
  padding: 0;
  margin-right: 1rem;
  > .menu {
    padding-left: 0 !important;
  }
}

.simple-taxonomy-menu {
  &::before {
    display: none;
  }

  &.menu {
    list-style: none;
    padding-left: 1rem;

    .menu__item {
      > .menu__link {
        display: inline-flex;
        position: relative;
        font-weight: 500;
        color: #3d3d3d;
        padding-left: 0;
        line-height: 1.2;
        padding-top: 4px;
        padding-bottom: 4px;
        color: #3d3d3d;
        text-decoration: none;

        &:hover {
          color: @theme-color-2;
        }

        &::before {
          content: '\f061';
          display: flex;
          align-items: center;
          font-size: 10px;
          margin-right: 8px;
          font-family: @font-family-awesome;
          color: #8c8c8c;
          font-weight: 500;
        }
      }

      &.expendable {
        > .menu__link {
          font-weight: 600;
          &::before {
            content: '\f063' !important;
          }
        }

        &.expanded {
          > .menu__link {
            &::before {
              content: '\f00c' !important;
              color: @theme-color-2 !important;
              font-weight: 600 !important;
            }
          }
        }
      }
    }
  }
}
