// News

.news-item {
  padding-bottom: 1em;
  margin-bottom: 1em;
  border-bottom: 1px solid @gray-lighter;

  .views-field-created {
    font-size: @font-size-xsmall;
    font-style: italic;
  }
}
