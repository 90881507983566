/*------------------------------------*\
  Payment Details
\*------------------------------------*/

#payment-details {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;

  br {
    display: none;
  }

  .order-remain-total-intro {
    font-weight: 600;
    align-self: flex-start;
    color: #000;
  }

  .purchase-exceed-subtotal {
    width: 100%;
    text-align: right;
  }

  .order-remain-total {
    font-weight: 600;
    color: #000;
    border-top: 1px solid #d8d8d8;
  }

  .order-remain-total,
  fieldset.panel {
    display: flex;
    padding: 0;
    margin: 5px 0;
    width: 300px;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
  }

  fieldset.panel {
    box-shadow: none;
    border: none;

    legend {
      background-color: #fff;
      border: none;
      padding: 0;
    }

    .panel-title {
      font-size: 16px;
    }

    .panel-body {
      padding: 0;
    }
  }
}
