.page-user-order-report {
  #sort-table {
    > tbody {
      tr {
        td:nth-child(3) {
          font-weight: 600;
        }

        td:nth-child(2) {
          .table-responsive {
            font-size: @font-size-small;

            td,
            th {
              padding: 0.25em;
            }
          }
        }
      }
    }
  }
}
