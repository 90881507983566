/*------------------------------------*\
  Survey Popup
\*------------------------------------*/
.rww-survey-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 0;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

  /** popup modal **/
  .popup-modal {
    display: flex;
    background: url('../images/survey-popup-bg.jpg') 0 0 no-repeat #fff;
    background-size: cover;
    width: 750px;
    height: auto;
    min-height: 300px;
    max-width: 90%;

    /** Content **/
    &__content {
      width: 100%;
      padding: 1rem;
      color: #fff;

      h2,
      h3 {
        font-weight: 400;
        color: @theme-color-1;
      }

      h4,
      h5 {
        font-weight: 400;
        color: #fff;
      }

      @media @normal {
        width: 65%;
        padding: 2rem;
      }
    }
  }
}
