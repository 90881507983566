// User Management Module
.not-front {
  .footer-links {
    @media all and (max-width: 1200px) {
      flex: 0 0 70% !important;
    }
  }

  #block-koda-user-management-active-user-count,
  #block-koda-user-management-minutes-saved-count {
    &.hide-block {
      right: 0;
    }
  }
}

#block-koda-user-management-active-user-count,
#block-koda-user-management-minutes-saved-count {
  .myanimated;
  .transition(all 1000ms ease);
  position: relative;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: auto !important;

  @media all and (max-width: 1200px) {
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    .block-title {
      text-align: center;
    }
    &.hide-block {
      bottom: 0;
      transform: translateY(100%);
    }
  }

  // Animate modes
  &.hide-block {
    position: absolute;
    visibility: hidden;
    right: 0;
    opacity: 0;
    z-index: -100;
  }

  &.show-block {
    position: relative;
    opacity: 1;
    visibility: visible;
    z-index: 10;
  }

  .block-title {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: @font-size-h1;
    font-weight: 700;
    font-size: 22px;
    width: 100%;
    margin-bottom: 0;
  }

  @media @normal {
    margin-left: 10%;
    flex: 1;
    flex-direction: row;
    text-align: left;
    .block-title {
      width: 200px;
      justify-content: flex-start;
      text-align: left;
    }
  }

  @media @xwide {
    flex: 0 0 auto;
  }

  .counter-container {
    .flex-container(column, wrap, flex-start, center, flex-start);
    @media @normal {
      .flex-container(row, wrap, flex-start, center, flex-start);
    }
  }

  .counter {
    .flex-element(0, 0, 1, 30%);
    color: @brand-primary;
    font-size: 50px;
    font-weight: 400;
    margin: 0;
  }

  .counter-text {
    flex: 1;
    padding-left: 3%;
    color: #fff;
    font-size: 12px;
    * {
      font-size: inherit;
    }
    a {
      color: @brand-primary;
    }
    p {
      margin: 0;
      line-height: 1.2;
    }
  }
}
