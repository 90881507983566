.form-control {
  height: 30px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 14px;
}

.form-select,
.form-control {
  color: #000;
  border-radius: 0 !important;
  &::placeholder {
    color: #9e9e9e;
  }
  ::-webkit-input-placeholder {
    color: #9e9e9e;
  }
  :-ms-input-placeholder {
    color: #9e9e9e;
  }
}

.field-widget-multiselect {
  position: relative;
  width: 100%;
  float: left;
}

.form-item {
  &.form-type-select {
    display: inline-block;
  }
  &.multiselect {
    position: relative;
    float: left;
    display: block;
    padding-bottom: 1rem;
  }
}
