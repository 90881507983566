// Clients

.clients-item {
  .field-content {
    display: block;
    position: relative;
  }

  .default-state {
    text-align: center;
    padding: 1em;
    border: 1px solid @gray-lighter;

    img {
      margin: 0 auto;
    }
  }

  .overlay {
    background: rgba(255, 154, 46, 0.95);
    color: @white;
    position: absolute;
    padding: 1em;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    display: block;
    .opacity(0);
    .myanimated;
    .box-shadow(0 0 15px rgba(0, 0, 0, 0.15));

    > * {
      color: @white;
    }

    .learnmore {
      a {
        .btn;
        background: @white;
        color: @theme-color-1;
        border-color: @white;
      }
    }
  }

  &:hover {
    .overlay {
      .opacity(1);
    }
  }
}
