/*------------------------------------*\
  ProductInStock Mixin
\*------------------------------------*/
.productInStock {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #7dc323;
  color: #fff;
  margin-top: 15px;
  padding: 0px 10px;
  min-width: 80px;
  text-align: center;
  line-height: 2;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 16px 0 16px 5px;
    border-color: transparent transparent transparent #ffffff;
  }
}
