html {
  width: 100vw;
  body {
    width: 100vw;
    overflow-x: hidden;
  }
}

.full {
  position: relative;
  width: 100%;
}

.padd-left-30 {
  padding-left: 30px;
}

.padd-right-30 {
  padding-right: 30px;
}

.padd-top-30 {
  padding-top: 30px;
}

.padd-bottom-30 {
  padding-bottom: 30px;
}

button,
.btn {
  &:hover,
  &:active,
  &:visited,
  &:focus {
    outline: none;
  }
}
