/*------------------------------------*\
  Order
\*------------------------------------*/
#complete-return-order-item-form {
  .form-type-select {
    .line-item-qty {
      width: 100px;
    }
  }
}

.order_tracking_trigger {
  .btn;
  border-radius: 72px;
  margin-right: 0.5rem;
  padding: 4px 1rem;
  background-color: #519fe7;
  border-color: #519fe7;
  color: #fff;
}
