.page-account {
  form {
    > div {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      > .btn.form-submit {
        margin: 0;
      }
    }

    .table-responsive {
      width: 100%;
      margin-top: 1rem;
    }
  }

  .container-inline-date {
    margin-right: 10px;
    .form-item {
      margin-bottom: 0 !important;
    }

    > .form-item {
      display: flex;
      flex-direction: row;

      > label {
        flex: 1;
      }

      .form-text {
        width: 100px;
      }
    }
  }
  .report-shortcut {
    display: inline;
    width: 100%;
    margin-bottom: 1rem;
    a {
      font-weight: 600;
      text-decoration: underline;
    }
  }

  .form-item {
    flex: 0 0 100%;
    width: 100%;
    display: inline-flex;
    margin-bottom: 10px;
    > label {
      margin-bottom: 0;
      width: 160px;
    }
  }

  .form-control {
    &.form-select {
      width: auto;
    }
  }
}
