/*------------------------------------*\
  Header
\*------------------------------------*/
.header {
  .myanimated;
  position: sticky;
  color: @text-color;
  width: 100%;
  z-index: 100;
  top: 0;
  padding: 0.5rem;
  font-size: @font-size-xsmall;
  background-color: #000;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;

  .logo {
    margin-right: auto;
    img {
      max-width: 100%;
    }
  }

  .topbar {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @media @normal {
      flex: 1;
      padding-right: 2rem;
    }
  }

  .region-topbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    flex: 1;

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .block-tam-account-logic {
    margin-right: auto;
  }

  .block-uc-ajax-cart-alt {
    width: 100%;

    h2,
    .cart-block-items {
      display: none;
    }

    .cart-block-summary {
      background-color: #000;
      tbody {
        display: flex;
        flex-wrap: wrap;
      }
      .cart-block-summary-total {
        display: none;
      }
      .cart-block-summary-items {
        position: relative;
        font-size: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        &:before {
          content: '\f07a';
          font-family: @font-family-awesome;
          color: @gray-lighter;
          display: inline-block;
          margin-right: 4px;
          font-size: 1.4rem;
        }
        .num-items {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 15px;
          width: 2rem;
          height: 100%;
          color: #fff;
          text-align: center;
          &::after {
            z-index: -1;
            border-radius: 100%;
            content: '';
            width: 25px;
            height: 25px;
            background-color: #f58220;
            position: absolute;
          }
        }
      }
      .cart-block-summary-links {
        margin-left: 0.5rem;
        > td {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .links {
          list-style: none;
          margin: 0;
          padding: 0;
          a {
            color: #fff;
          }
          .cart-block-checkout {
            display: none;
          }
        }
      }
    }
  }

  // Nav
  .nav {
    > li {
      > a {
        &.btn {
          margin: 0;
        }
        &:hover {
          background-color: unset;
        }
      }
    }
  }

  // Contact Button
  .btn {
    &.contact-btn {
      padding: 0.3rem 1.5rem;
      &:hover {
        background-color: @theme-color-1;
        border-color: @theme-color-1;
        color: @black;
      }
    }
  }
}
