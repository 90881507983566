// Typography

h4.print-status-label {
  position: relative;
  margin-top: 0;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  width: 100%;
  background-color: @gray-lightest;
  padding: 8px;
  display: block;

  @media @tablet {
    display: none;
  }
}

/** Typography **/
h2,
h3,
h4,
p {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h2 {
  line-height: 1;
  margin-top: 0;
  font-size: 20px;
  font-weight: 900;
  color: @theme-color-2;

  @media @normal {
    font-size: 45px;
  }
}

h4 {
  color: inherit;
  font-size: 18px;
  font-weight: 600;
}

p {
  font-size: 14px;
  margin-bottom: 1rem;
}

b,
strong {
  font-weight: 600;
}

/** Responsive **/
@media @tablet {
  h2 {
    font-size: 45px;
  }

  h4 {
    font-size: 25px;
  }

  p {
    font-size: 16px;
  }

  .btn {
    width: auto;
  }

  h2,
  h3,
  h4,
  p {
    margin-bottom: 1rem;
  }
}
