/*------------------------------------*\
  Survey Webform
\*------------------------------------*/
article[about*='survey'].node {
  form.webform-client-form {
    > div {
      max-width: 100%;
    }

    /** Responsive **/
    @media @tablet {
      > div {
        width: 700px;
        margin: 0 auto;
      }
    }
  }
}
