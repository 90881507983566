.product-promotions {
  position: relative;
  width: 100%;
  min-height: 200px;
  text-align: center;
  margin-bottom: 2rem;
}

.promotions-wrapper {
  position: relative;
  max-width: 410px;
  margin: 0 auto;
  > img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  > .promotions-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
  }

  > .promotions-video {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0;
    iframe {
      width: 100%;
      margin: 0;
    }
  }
}
