// My Account
.region-sidebar-left {
  .block-tam-account-logic {
    background: #ffffff;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #d8d8d8;
    box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.15);

    > div {
      &:not(:last-child) {
        margin-bottom: 0.8rem;
      }
    }

    #selected-account {
      #selected-account-name {
        a {
          font-size: 20px;
          color: #000;
          font-weight: 600;
          line-height: 1;
          text-decoration: none;
        }
        .u-account-m {
          .myanimated;
          text-align: center;
          width: 48px;
          height: 48px;
          background-color: #fff;
          border: 1px solid #e5e5e5;
          border-radius: 100%;
          align-items: center;
          justify-content: center;
          &:hover {
            background-color: @brand-primary;
            border-color: @brand-primary;
          }
        }
      }
    }

    #selected-staff-shortcuts,
    #login-user-shortcuts {
      display: flex;
      flex-direction: column;
    }

    #selected-staff {
      display: flex;
      flex-wrap: wrap;
      #selected-staff-name {
        display: flex;
        width: 100%;
        margin-bottom: 0.8rem;
        > a {
          flex: 0 0 50px;
        }
        > span {
          flex: 1;
        }
      }
    }

    .btn {
      padding: 0 1rem;
      background-color: @theme-color-2;
      border-color: @theme-color-2;
      color: #fff;
      font-weight: normal;
      margin-bottom: 0;
      min-width: 60%;
      .fa {
        margin-right: 0.5rem;
      }
      &:hover {
        background-color: #000;
        border-color: #000;
      }
    }
    .btn-reset {
      border: 1px solid #d8d8d8;
      font-size: 10px;
      padding: 2px;
    }
  }
}
