/*------------------------------------*\
  Float Notes
\*------------------------------------*/

.float-notes {
  position: relative;
  width: 100%;
  background: #f7dbcb;
  padding: 2rem 3rem;
  font-size: @font-size-small;
  border-radius: 6px;
  .myanimated;

  // Collapsed
  &.collapsed-tool {
    padding: 0;
    width: 50px;
    #selected-staff-details {
      display: none;
      .opacity(0);
      .myanimated;
    }
    #selected-staff-container-button {
      position: relative;
      padding: 0;
    }
    #selected-staff-container {
      justify-content: center;
      padding: 10px;
    }
  }

  #selected-staff-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    .opacity(1);
    animation-delay: 0.3s;
    .myanimated;
  }

  #selected-staff-details {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: space-between;
    width: 100%;
  }

  .label {
    padding: 0;
    margin: 0.75em 0 8px 0;
    font-size: @font-size-small;
    font-weight: 600;
    font-size: 16px;
    color: #414141;
    text-align: left;
  }

  h2 {
    font-size: @font-size-base;
    text-transform: uppercase;
    white-space: nowrap;
    font-weight: 600;
  }

  #selected-staff-container-button {
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px 10px;
    &:after {
      color: #f37519;
      content: '\f00d';
      font-family: @font-family-awesome;
      font-size: @font-size-h4;
      display: block;
      visibility: visible;
      text-align: center;
      left: 0;
      top: 1rem;
      width: 2rem;
      font-weight: normal;
      line-height: 1;
      .myanimated;
    }

    &.collapsed-btn {
      display: inline-block;
      &:after {
        content: '\f00a';
        top: 0;
      }
    }
  }

  #selected-staff-notes {
    max-width: 30%;
  }
}
