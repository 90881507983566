// Main Sidebar - Product
.main-sidebar {
  .myanimated;
  display: flex;
  flex-direction: column;
  margin-right: 0;
  right: 0;
  width: 0px;
  overflow-x: hidden;
  position: fixed;
  height: 100%;
  //transform: translateX(100%);

  // Open
  &.open {
    visibility: visible;
    right: 0;
    top: 0;
    margin-right: 0;
    width: 100vw;
    z-index: 100;
    overflow: visible;
  }

  @media @tablet {
    &.open {
      width: @main-sidebar-width;
    }
  }

  @media @xwide {
    position: relative;
    &.open {
      position: relative;
      height: auto;
      width: @main-sidebar-width;
    }
  }

  // Inner
  &__inner {
    position: fixed;
    height: 100%;
    top: 0;
    padding: 1.5rem 2rem 4rem 2rem;
    background-color: #fff;
    border: 1px solid #d8d8d8;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
    .region-main-sidebar {
      width: 100%;
      padding-bottom: 6rem;
      @media @tablet {
        width: calc(@main-sidebar-width - 4.5rem);
      }
    }
  }

  // Region
  .region-main-sidebar {
    height: 80vh;
    overflow-y: scroll;
    padding-bottom: 2rem;
  }
}
