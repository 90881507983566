form {
  &[id*='report-account-shipping'] {
    width: 100%;
    .views-exposed-form {
      .views-exposed-widgets {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
      }
      .views-exposed-widget {
        display: flex;
        flex-direction: row;
        padding: 0 1rem 1rem 0;

        .form-control {
          display: flex;
          margin-bottom: 0;
          border-radius: 0;
        }

        > .description {
          display: flex;
          align-items: center;
          order: 0;
          padding-right: 1rem;
          line-height: 1;
        }
        > .views-operator,
        > .views-widget {
          order: 1;
        }
      }
    }
  }
}
