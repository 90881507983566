// Buttons
.btn-secondary {
  .button-variant(@white; @theme-color-2; @theme-color-2);
  display: flex;
  flex-direction: row;
  color: @white;
  justify-content: center;

  .fa {
    display: flex;
    color: inherit;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    line-height: 1;
  }
}

.btn {
  &.contact-btn {
    padding: 2px 10px;
  }
}
