/** Dashboard **/
.dashboard-message {
  .dashboard-wrapper;
  .block-content-wrapper {
    display: flex;
    flex-direction: column;
    .content-link {
      display: flex;
      align-items: center;
      color: #000;
    }
  }
  // Read more
  .dashboard-readmore {
    position: relative;
    + [data-readmore-toggle] {
      margin-top: 4px;
      text-decoration: none;
      &::before {
        content: '\f106';
        font-family: @font-family-awesome;
        margin-right: 4px;
      }
    }
    &[aria-expanded*='false'] {
      + [data-readmore-toggle] {
        &::before {
          display: none;
        }
      }
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 45px;
        background: -moz-linear-gradient(
          top,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 100%
        ); /* FF3.6-15 */
        background: -webkit-linear-gradient(
          top,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
      }
      @media @tablet {
        &::after {
          height: 20px;
          bottom: -10px;
        }
      }
    }
  }
}

// Common Block Dash Report
.dash-report {
  .block-icon {
    margin-bottom: 0.5rem;
  }
  .block-content-wrapper {
    display: flex;
    flex-direction: column;
    .content-link {
      display: flex;
      align-items: center;
      color: #000;
      .fa {
        color: @theme-color-2;
      }
      &:hover {
        color: @theme-color-2;
      }
    }
  }

  &.block {
    > h2 {
      font-size: 16px;
      text-align: left;
      font-weight: 600;
    }
  }

  #staff-delete {
    &::before {
      content: '\f2ed';
      font-family: @font-family-awesome;
      font-weight: bold;
      font-size: 15px;
    }
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 0;
    text-decoration: none;
    margin: 0 auto;
  }

  table.table {
    a {
      color: @theme-color-2;
    }
    > thead {
      > tr {
        > th {
          font-weight: 600;
          &:last-child {
            text-align: right;
          }
        }
      }
    }
    > thead,
    > tbody {
      > tr {
        > th,
        > td {
          border-top: none;
          border-bottom: none;
          font-size: 15px;
          &:not(:first-child) {
            border-left: 1px solid #e9e9e9;
          }
          &:not(:last-child) {
            text-align: left;
          }
        }
      }
    }
  }
}

// Icons
.acc-icons-block {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @media @wide {
    display: flex;
  }
  &::before {
    display: none;
  }
  .acc-icons-item {
    display: flex;
    align-items: stretch;
    justify-content: center;
    color: #000;
    flex: 0 0 100%;
    border-left: 2px solid #000;
    min-height: 122px;
    margin-bottom: 2rem;
    @media @tablet {
      flex: 0 0 calc(50% - 1rem);
      &:first-child,
      &:nth-child(2n + 1) {
        margin-right: 1rem;
      }
      &:nth-child(2n) {
        margin-left: 1rem;
      }
    }
    @media @xwide {
      flex: 1;
      &:not(:first-child) {
        margin-left: 2rem;
      }
    }
    > a {
      .myanimated;
      display: flex;
      width: 100%;
      height: 100%;
      padding: 15px;
      line-height: 1;
      background-color: @brand-primary;
      color: #000;
      font-size: 18px;
      font-weight: 600;
      align-items: center;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
      border-radius: 0px 0px 20px 0px;
      &:hover {
        text-decoration: none;
        background-color: @theme-color-2;
      }
    }
    .acc-icons-icon {
      margin-right: 10px;
      img {
        height: 70px;
      }
    }
  }
}

// Menu
.dashboard-menu {
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
  .menu {
    display: flex;
    border-bottom: 1px solid #ebebeb;

    > li {
      font-size: 12.5px;
      line-height: 1.2;
      cursor: pointer;
      &:not(:first-child) {
        margin-left: 1rem;
      }
      &.open {
        border-bottom: 2px solid @theme-color-2;
        > a {
          color: #000;
        }
      }
      > a {
        background-color: unset;
        color: #6f6f6f;
        font-size: inherit;
        padding-left: 0;
        padding-right: 0;
        &:hover {
          background-color: unset;
        }
      }
      @media @tablet {
        font-size: 16px;
      }
    }
  }
}

// Dash block
.dash-block {
  position: relative;
  width: 100%;
  height: 100%;
  display: none;
  .fa {
    display: flex;
    font-family: @font-family-base;
    &:before {
      display: inline-block;
      margin-right: 0.5rem;
      font-family: @font-family-awesome;
      color: inherit;
    }
  }
  .block-header {
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
    font-size: 18px;
  }
  .block-content-wrapper {
    flex: 1;
  }
  .block-btn {
    display: block;
    text-align: center;
    padding-top: 1em;
    a {
      .btn;
    }
  }
  .block-link {
    position: relative;
    width: 100%;
    margin-top: auto;
  }

  .pagination {
    margin-bottom: 0;
  }
  .view-footer {
    margin-top: 1rem;
  }
}

// Vert Scrollable View Table
.dash-view-scrollable {
  .view-content {
    max-height: 250px;
    overflow-y: scroll;
  }
}

// Dash Staff
.dash-staff {
  padding: 1rem;
  display: flex;
  flex-direction: column;

  .block-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    .block-header {
      width: auto;
      margin: 0;
      font-weight: 600;
      line-height: 1.2;
      font-size: 17px;
      padding: 0;
      margin-right: 1rem;
    }
    .block-digits {
      position: relative;
      font-size: 55px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }
  }
  .block-content-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    .block-link {
      margin-top: auto;
    }
    .btn {
      min-width: 202px;
      justify-content: flex-start;
      margin-right: 0;
      .fa {
        margin-right: 4px;
      }
    }
  }
}

// Region Content Dashboard
.region-content-dashboard,
.region-staff-dashboard,
.region-bottom-dashboard {
  .dashboard-wrapper;
}

.region-staff-dashboard {
  display: flex;
  flex-direction: column;

  @media @xwide {
    flex-direction: row;
    .block {
      flex: 1;
    }
    .dash-staff {
      padding-left: 2rem;
    }
  }
  @media @tablet {
    .dash-staff {
      flex: 0 0 300px;
      width: 300px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

// Common Dashboard wrapper with border
.dashboard-wrapper {
  position: relative;
  width: 100%;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 0px 0px 20px 0px;
  padding: 1rem;
  margin-bottom: 2rem;
}

.dash-order {
  .block-header-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 1rem;

    .block-header {
      width: auto;
      margin: 0;
      font-weight: 600;
      font-size: 17px;
      padding: 0;
      margin-right: 3rem;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    > li {
      display: flex;
      font-weight: 500;
      > .fa {
        align-items: center;
        color: @theme-color-2;
      }
      > a {
        margin-left: 10px;
        font-weight: 600;
      }
    }
  }
}
